import { useState } from 'react';

/**
 * Custom hook to manage sharing state and logic.
 */
export const useShare = () => {
  const [isSharing, setIsSharing] = useState(false);
  const [shareOptions, setShareOptions] = useState({
    url: '',
    title: '',
    content: '',
  });
  const [sharedArticleId, setSharedArticleId] = useState(null);

  /**
   * Open the share dialog with specific article details.
   * @param {Object} shareData - The data required to share.
   * @param {String} shareData._id - ID of the article.
   * @param {String} shareData.title - Title of the article.
   * @param {String} shareData.content - Content of the article.
   * @param {String} shareData.url - URL to be shared.
   */
  const openShare = ({ _id, title, content, url }) => { // Modified parameter
    setIsSharing(true);
    setSharedArticleId(_id);
    setShareOptions({ url, title, content });
  };

  /**
   * Close the share dialog and reset share options.
   */
  const closeShare = () => {
    setIsSharing(false);
    setSharedArticleId(null);
    setShareOptions({ url: '', title: '', content: '' });
  };

  return {
    isSharing,
    shareOptions,
    sharedArticleId,
    openShare,
    closeShare,
  };
};