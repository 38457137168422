// src/reducers/sharedThunks.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProfile, selectProfileData } from './profileSlice';
import { fetchUserSubscriptions } from './subscriptionsSlice';
import { fetchBookmarks } from './bookmarksSlice';
import { handleError } from '../utils/errors/errorHandler'; // Add this import

/**
 * Thunk to fetch profile data.
 */
export const fetchProfileData = createAsyncThunk(
  'shared/fetchProfileData',
  async (_, { dispatch, rejectWithValue }) => { // Added getState
    try {
      // Always dispatch getProfile to fetch fresh profile data on refresh.
      await dispatch(getProfile());
    } catch (error) {
      const errorMessage = handleError(error, 'fetchProfileData');
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Thunk to fetch user subscriptions.
 */
export const fetchUserSubscriptionsData = createAsyncThunk(
  'shared/fetchUserSubscriptionsData',
  async (_, { dispatch, getState, rejectWithValue }) => { // Added getState
    try {
      const state = getState();
      const profileData = selectProfileData(state);
      // Always fetch subscriptions if profileData exists.
      if (profileData) {
        await dispatch(fetchUserSubscriptions({ profileId: profileData._id }));
      }
    } catch (error) {
      const errorMessage = handleError(error, 'fetchUserSubscriptionsData');
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Thunk to fetch bookmarks.
 */
export const fetchUserBookmarks = createAsyncThunk(
  'shared/fetchUserBookmarks',
  async (_, { dispatch, getState, rejectWithValue }) => { // Added getState
    try {
      const state = getState();
      const profileData = selectProfileData(state);
      // Always fetch bookmarks when profileData exists.
      if (profileData) {
        await dispatch(fetchBookmarks({ profileId: profileData._id }));
      }
    } catch (error) {
      const errorMessage = handleError(error, 'fetchUserBookmarks');
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk to fetch All profile data, subscriptions, and bookmarks.
export const fetchProfileAll = createAsyncThunk(
  'shared/fetchProfileAll',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(fetchProfileData());
      await dispatch(fetchUserSubscriptionsData());
      await dispatch(fetchUserBookmarks());
    } catch (error) {
      const errorMessage = handleError(error, 'fetchProfileAll');
      return rejectWithValue(errorMessage);
    }
  }
);
