// src/reducers/authSlice.js

import { createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/auth.service';
import { createThunkWithErrorHandler, handleAsync } from '../utils/reduxUtils/reduxUtils';
import { fetchProfileAll } from './sharedThunks';

// Modify the common function for post-authentication actions
const handlePostAuth = (dispatch) => {
  // Instead of only fetching profile data, dispatch the complete thunk
  dispatch(fetchProfileAll());
};

// Async thunks for authentication actions using createThunkWithErrorHandler

/** Login User */
export const loginUser = createThunkWithErrorHandler(
  'auth/loginUser',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const tokenData = await AuthService.login(credentials);
      handlePostAuth(dispatch);
      return tokenData;
    } catch (error) {
      const detailedError = error.message || 'Login failed.'; // Use error.message directly
      return rejectWithValue(detailedError);
    }
  }
);

/** Register User */
export const registerUser = createThunkWithErrorHandler(
  'auth/registerUser',
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const tokenData = await AuthService.handleAuthRequest('/auth/register', userData);
      handlePostAuth(dispatch);
      return tokenData;
    } catch (error) {
      const detailedError = error.message || 'Registration failed.'; // Use error.message directly
      return rejectWithValue(detailedError);
    }
  }
);

/** Authenticate with Discord */
export const authenticateWithDiscord = createThunkWithErrorHandler(
  'auth/authenticateWithDiscord',
  async (code, { dispatch, rejectWithValue }) => {
    try {
      const tokenData = await AuthService.handleAuthRequest(`/auth/discord?code=${code}`);
      handlePostAuth(dispatch);
      return tokenData;
    } catch (error) {
      const detailedError = error.message || 'Authentication with Discord failed.'; // Use error.message directly
      return rejectWithValue(detailedError);
    }
  }
);

/** Handle Google Auth */
export const handleGoogleAuth = createThunkWithErrorHandler(
  'auth/handleGoogleAuth',
  async (code, { dispatch, rejectWithValue }) => {
    try {
      const tokenData = await AuthService.handleAuthRequest(`/auth/google?code=${code}`);
      handlePostAuth(dispatch);
      return tokenData;
    } catch (error) {
      const detailedError = error.message || 'Authentication with Google failed.'; // Use error.message directly
      return rejectWithValue(detailedError);
    }
  }
);

/** Logout User */
export const logoutUser = createThunkWithErrorHandler('auth/logoutUser', async () => {
  try {
    await AuthService.logout();
  } catch (error) {
    const errorMessage = error.message || 'Logout failed.'; // Use error.message directly
    throw new Error(errorMessage); // Modify this line
  }
});

/** Initialize Auth */
export const initializeAuth = createThunkWithErrorHandler(
  'auth/initializeAuth',
  async (_, { dispatch }) => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
      const isValid = AuthService.tokenIsValid(accessToken);
      if (isValid) {
        await dispatch(fetchProfileAll()); // Added
        return { isAuthenticated: true };
      } else {
        try {
          await AuthService.refreshToken();
          await dispatch(fetchProfileAll()); // Added
          return { isAuthenticated: true };
        } catch (error) {
          // Token refresh failed, user is not authenticated
          AuthService.handleLogout();
          return { isAuthenticated: false };
        }
      }
    } else {
      return { isAuthenticated: false };
    }
  }
);

/**
 * Initial state
 */
const initialState = {
  isAuthenticated: false,
  loginFormVisible: false,
  status: 'idle',
  error: null,
};

/**
 * Auth slice
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    showLoginForm: (state) => {
      state.loginFormVisible = true;
    },
    hideLoginForm: (state) => {
      state.loginFormVisible = false;
    },
  },
  extraReducers: (builder) => {
    // Initialize Auth
    handleAsync(builder, initializeAuth, (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    });

    // Login User
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.error = null;
        state.loginFormVisible = false;
        // Reload page to fetch fresh data
        window.location.reload();
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Login failed'; // Updated to use payload
        state.isAuthenticated = false;
      });

    // Register User
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.error = null;
        // Reload page to fetch fresh data
        window.location.reload();
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Registration failed'; // Updated to use payload
        state.isAuthenticated = false;
      });

    // Authenticate with Discord
    handleAsync(builder, authenticateWithDiscord);

    // Logout User
    builder
      .addCase(logoutUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Logout failed';
      });

    // Consolidate similar reducer logic using addMatcher
    builder
      // Handle pending state for both loginUser and registerUser
      .addMatcher(
        (action) =>
          action.type === loginUser.pending.type ||
          action.type === registerUser.pending.type,
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      // Handle fulfilled state for both loginUser and registerUser
      .addMatcher(
        (action) =>
          action.type === loginUser.fulfilled.type ||
          action.type === registerUser.fulfilled.type,
        (state) => {
          state.status = 'succeeded';
          state.isAuthenticated = true;
          state.error = null;
          state.loginFormVisible = false;
        }
      )
      // Handle rejected state for both loginUser and registerUser
      .addMatcher(
        (action) =>
          action.type === loginUser.rejected.type ||
          action.type === registerUser.rejected.type,
        (state, action) => {
          state.status = 'failed';
          state.error = action.payload || 'Authentication failed';
          state.isAuthenticated = false;
        }
      )
      // Handle rejected state for authenticateWithDiscord
      .addMatcher(
        (action) =>
          action.type === authenticateWithDiscord.rejected.type,
        (state, action) => {
          state.status = 'failed';
          state.error = action.payload || 'Authentication failed';
          state.isAuthenticated = false;
        }
      );
  },
});

// Export actions and reducer
export const { resetAuthState, showLoginForm, hideLoginForm } = authSlice.actions;
export default authSlice.reducer;

