import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { markArticleAsClicked, loadClickedArticles, addHistory } from '../reducers/historySlice';
import { handleError } from '../utils/errors/errorHandler'; // Import handleError

/**
 * Custom hook to manage clicked articles using Redux.
 */
export const useClickedArticles = () => {
  const dispatch = useDispatch();
  const clickedArticleIds = useSelector(state => state.history.clickedArticleIds) || []; // Ensure it's an array
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  // Load clicked articles from Redux store on mount
  useEffect(() => {
    dispatch(loadClickedArticles());
  }, [dispatch]);

  /**
   * Mark an article as clicked and add to history.
   * @param {String} articleId - ID of the clicked article.
   */
  const markAsClicked = (articleId) => {
    try {
      dispatch(markArticleAsClicked(articleId));
      if (isAuthenticated) {
        dispatch(addHistory(articleId));
      }
    } catch (error) {
      handleError(error, 'useClickedArticles.markAsClicked');
      // Optionally, handle the error as needed
    }
  };

  return {
    clickedArticleIds,
    markAsClicked,
  };
};