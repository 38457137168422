// src/reducers/profileSlice.js

import { createSlice } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import queryString from 'query-string';
import AuthService from '../services/auth.service';
import { createThunkWithErrorHandler } from '../utils/reduxUtils/reduxUtils';
import { handleError } from '../utils/errors/errorHandler'; // Add this import

/** Get Profile */
export const getProfile = createThunkWithErrorHandler(
  'profile/getProfile',
  async ({ id, params } = {}, { rejectWithValue }) => {
    if (!AuthService.isAuthenticated()) {
      return rejectWithValue('User is not authenticated');
    }

    if (!id) {
      id = AuthService.getUserId();
    }

    const query = queryString.stringify(params || {});
    let url = `/private/profile`;

    if (id) {
      url += `/${id}`;
    }

    if (query) {
      url += `?${query}`;
    }

    try {
      const response = await api.get(url);
      const profileData = response.data.data;

      return profileData; // Serializable data
    } catch (error) {
      const errorMessage = handleError(error, 'getProfile');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Update Profile */
export const updateProfile = createThunkWithErrorHandler(
  'profile/updateProfile',
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    if (!AuthService.isAuthenticated()) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await api.put(`/private/profile/${id}`, data);
      dispatch(getProfile({ id })); // Refresh profile data
      return response.data.data;
    } catch (error) {
      // Use handleError to provide specific error messages
      const errorMessage = handleError(error, 'updateProfile');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Change Password */
export const changePassword = createThunkWithErrorHandler(
  'profile/changePassword',
  async ({ id, data }, { rejectWithValue }) => {
    if (!AuthService.isAuthenticated()) {
      return rejectWithValue('User is not authenticated');
    }

    if (!id) {
      id = AuthService.getUserId();
    }

    try {
      const response = await api.put(`/private/profile/reset/${id}`, data);
      return response.data.data;
    } catch (error) {
      const errorMessage = handleError(error, 'changePassword');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Send Verification Email */
export const sendVerificationEmail = createThunkWithErrorHandler(
  'profile/sendVerificationEmail',
  async ({ email, username }, { rejectWithValue }) => {
    if (!AuthService.isAuthenticated()) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await api.post('/auth/sendVerificationEmail', { email, username });
      return { message: response.data.message || 'Verification email sent', ...response.data.data }; // Ensure message is included
    } catch (error) {
      const errorMessage = handleError(error, 'sendVerificationEmail');
      return rejectWithValue(errorMessage); // Ensure error message is returned
    }
  }
);

/** Verify Email */
export const verifyEmail = createThunkWithErrorHandler(
  'profile/verifyEmail',
  async ({ email, verificationToken }, { rejectWithValue }) => {
    if (!AuthService.isAuthenticated()) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await api.post('/auth/verifyEmail', { email, verificationToken });
      return { message: response.data.message || 'Your email is now verified.', ...response.data.data }; // Include message in the payload
    } catch (error) {
      const errorMessage = handleError(error, 'verifyEmail');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Send Forgot Password Email */
export const sendForgotPasswordEmail = createThunkWithErrorHandler(
  'profile/sendForgotPasswordEmail',
  async ({ identifier }, { rejectWithValue }) => {
    try {
      const response = await api.post('/auth/sendForgotPasswordEmail', { identifier });
      return { message: response.data.message || 'Reset info sent', data: response.data.data };
    } catch (error) {
      const errorMessage = handleError(error, 'sendForgotPasswordEmail');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Reset Password via Email */
export const resetPasswordViaEmail = createThunkWithErrorHandler(
  'profile/resetPasswordViaEmail',
  async ({ email, newPassword, forgotPasswordToken }, { rejectWithValue }) => {
    try {
      const response = await api.put('/auth/updatePassword', {
        email,
        newPassword,
        forgotPasswordToken,
      });
      return response.data.data;
    } catch (error) {
      const errorMessage = handleError(error, 'resetPasswordViaEmail');
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Initial state
 */
const initialState = {
  profileData: null,
  
  // Separate status and error for each thunk
  getProfileStatus: 'idle',
  getProfileError: null,

  updateProfileStatus: 'idle',
  updateProfileError: null,

  changePasswordStatus: 'idle',
  changePasswordError: null,

  sendVerificationEmailStatus: 'idle',
  sendVerificationEmailError: null,

  verifyEmailStatus: 'idle',
  verifyEmailError: null,

  sendForgotPasswordEmailStatus: 'idle',

  resetPasswordViaEmailStatus: 'idle',
  resetPasswordViaEmailError: null,
};

/**
 * Profile slice
 */
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileState: () => initialState,

    clearEmailVerification: (state) => {
      state.sendVerificationEmailStatus = 'idle';
      state.sendVerificationEmailError = null;
      
      state.verifyEmailStatus = 'idle';
      state.verifyEmailError = null;
    },

    clearProfileErrors: (state) => {
      state.getProfileError = null;
      state.updateProfileError = null;
      state.changePasswordError = null;
      state.sendVerificationEmailError = null;
      state.verifyEmailError = null;
      state.resetPasswordViaEmailError = null;
    },
    clearErrors: (state) => {
      state.getProfileError = null;
      state.updateProfileError = null;
      // ...clear other errors...
    },
    clearUpdateProfileStatus: (state) => {
      state.updateProfileStatus = 'idle';
      state.updateProfileError = null;
    },
  },
  extraReducers: (builder) => {
    // Get Profile
    builder
      .addCase(getProfile.pending, (state) => {
        state.getProfileStatus = 'loading';
        state.getProfileError = null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileStatus = 'succeeded';
        state.profileData = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.getProfileStatus = 'failed';
        state.getProfileError = action.payload || 'Failed to fetch profile';
      });

    // Update Profile
    builder
      .addCase(updateProfile.pending, (state) => {
        state.updateProfileStatus = 'loading';
        state.updateProfileError = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.updateProfileStatus = 'succeeded';
        state.updateProfileError = null; // Clear previous errors
        state.profileData = {
          ...state.profileData,
          ...action.payload,
        };
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateProfileStatus = 'failed';
        state.updateProfileError = action.payload;
      });

    // Change Password
    builder
      .addCase(changePassword.pending, (state) => {
        state.changePasswordStatus = 'loading';
        state.changePasswordError = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.changePasswordStatus = 'succeeded';
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordStatus = 'failed';
        state.changePasswordError = action.payload;
      });

    // Similar handling for other thunks...
    // Send Verification Email
    builder
      .addCase(sendVerificationEmail.pending, (state) => {
        state.sendVerificationEmailStatus = 'loading';
        state.sendVerificationEmailError = null;
      })
      .addCase(sendVerificationEmail.fulfilled, (state) => {
        state.sendVerificationEmailStatus = 'succeeded';
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.sendVerificationEmailStatus = 'failed';
        state.sendVerificationEmailError = action.payload;
      });

    // Verify Email
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.verifyEmailStatus = 'loading';
        state.verifyEmailError = null;
      })
      .addCase(verifyEmail.fulfilled, (state) => { // Removed unused 'action'
        state.verifyEmailStatus = 'succeeded';
        if (state.profileData) {
          state.profileData.isEmailVerified = true;
        }
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.verifyEmailStatus = 'failed';
        state.verifyEmailError = action.payload;
      });

    // Send Forgot Password Email
    builder
      .addCase(sendForgotPasswordEmail.pending, (state) => {
        state.sendForgotPasswordEmailStatus = 'loading';
      })
      .addCase(sendForgotPasswordEmail.fulfilled, (state) => {
        state.sendForgotPasswordEmailStatus = 'succeeded';
      })
      .addCase(sendForgotPasswordEmail.rejected, (state) => {
        state.sendForgotPasswordEmailStatus = 'failed';
      });

    // Reset Password via Email
    builder
      .addCase(resetPasswordViaEmail.pending, (state) => {
        state.resetPasswordViaEmailStatus = 'loading';
        state.resetPasswordViaEmailError = null;
      })
      .addCase(resetPasswordViaEmail.fulfilled, (state) => {
        state.resetPasswordViaEmailStatus = 'succeeded';
      })
      .addCase(resetPasswordViaEmail.rejected, (state, action) => {
        state.resetPasswordViaEmailStatus = 'failed';
        state.resetPasswordViaEmailError = action.payload;
      });
  },
});

// Selectors
export const selectProfileData = (state) => state.profile.profileData;

// Export actions and reducer
export const { resetProfileState, clearEmailVerification, clearProfileErrors, clearErrors, clearUpdateProfileStatus } = profileSlice.actions;
export default profileSlice.reducer;
