// src/reducers/articleSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { uniqBy } from 'lodash';
import api from '../api/axiosInstance';
import { handleError } from '../utils/errors/errorHandler';

/**
 * Unified fetchArticles action using createAsyncThunk
 * Fetches articles based on the page type and parameters.
 */
export const fetchArticles = createAsyncThunk(
  'articles/fetchArticles',
  async ({ pageType, params, append = false }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const isAuthenticated = state.auth.isAuthenticated;
      const profileId = state.profile.profileData?._id;

      // Ensure filters are correctly applied
      const cleanParams = {
        ...Object.fromEntries(
          Object.entries(params || {}).filter(([, value]) => value !== null && value !== undefined)
        ),
        page: params.page || 1, // Ensure page is defined
        category: params.category || '', // Default category
        feed_type: params.feed_type || 'all', // Ensure feed_type is included
        search: params.search || '', // Ensure search is included
      };

      let endpoint;

      switch (pageType) {
        case 'home':
          if (!isAuthenticated) {
            throw new Error('Authentication required');
          }
          endpoint = '/private/article/subscribed';
          break;
        case 'topic':
          if (params.topicId) { // Fix: Check params.topicId instead of cleanParams.topicId
            if (isAuthenticated && profileId) {
              endpoint = `/private/article/topic/${params.topicId}`; // Fix: Use params.topicId
            } else {
              endpoint = `/public/article/topic/${params.topicId}`;
            }
            delete cleanParams.topicId; // Move this after creating the endpoint
          } else {
            throw new Error('topicId is required for topic pageType');
          }
          break;
        case 'category':
          endpoint = `/public/article/cat_name/${cleanParams.category}`;
          break;
        case 'search':
          endpoint = isAuthenticated
            ? `/private/article/subscribed`
            : `/public/article`;
          break;
        default:
          endpoint = `/public/article`;
          break;
      }

      // Append query parameters if any
      const query = queryString.stringify(cleanParams);
      const url = query ? `${endpoint}?${query}` : endpoint;

      // Make the API request using Axios instance
      const response = await api.get(url);

      // Assuming the API response structure is { data: [...] }
      const data = response.data.data || response.data;

      return {
        items: data,
        append,
        page: params.page || 1,
        limit: params.limit || 25, // Default limit
      };
    } catch (error) {
      const errorMessage = handleError(error, 'fetchArticles');
      console.error('fetchArticles error:', errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Fetches article detail by ID
 */
export const fetchArticleDetail = createAsyncThunk(
  'articles/fetchArticleDetail',
  async (id, { rejectWithValue }) => {
    try {
      // Corrected endpoint to use singular 'article'
      const endpoint = `/public/article/${id}`;
      const response = await api.get(endpoint);

      const data = response.data.data || response.data;

      return data;
    } catch (error) {
      // Enhanced error handling for 404 Not Found
      if (error.response && error.response.status === 404) {
        return rejectWithValue('Article not found');
      }
      const errorMessage = handleError(error, 'fetchArticleDetail');
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchLatestArticleSince = createAsyncThunk(
  'articles/fetchLatestArticleSince',
  async (payload, { rejectWithValue }) => {
    try {
      const { lastDate, pageType, topicId, feed_type, category, subscribedTopics, search } = payload;
      let endpoint = pageType === 'home' ? '/private/article/latest-since' : '/public/article/latest-since';

      const query = new URLSearchParams({ lastDate });
      if (feed_type) query.append('feed_type', feed_type);
      if (topicId) query.append('topicId', topicId);
      if (subscribedTopics) query.append('subscribedTopics', subscribedTopics);
      if (category) query.append('category', category);
      if (search) query.append('search', search); // include search param

      const url = `${endpoint}?${query.toString()}`;
      const response = await api.get(url);

      const { hasNew } = response.data.data;
      return { hasNew };
    } catch (error) {
      return rejectWithValue(handleError(error, 'fetchLatestArticleSince'));
    }
  }
);

export const triggerRefresh = () => (dispatch) => {
  dispatch(clearArticles());
  // Optionally refetch articles or emit an event
};

// Consolidated Article Slice for managing the state
const articleSlice = createSlice({
  name: 'articles',
  initialState: {
    list: [],
    listStatus: 'idle',
    listError: null,
    articleDetail: null,
    detailStatus: 'idle',
    detailError: null,
    hasMore: true, // Ensure hasMore is part of the state
    currentPage: 1, // Track current page for pagination
    failureCount: 0, // NEW: Track consecutive failures
  },
  reducers: {
    clearArticles: (state) => {
      state.list = [];
      state.listStatus = 'idle';
      state.listError = null;
      state.hasMore = true; // Reset hasMore when clearing articles
      state.currentPage = 1; // Reset currentPage when clearing articles
      state.failureCount = 0; // NEW: Reset failure count
    },
    resetArticles: (state) => {
      state.list = [];
      state.listStatus = 'idle';
      state.hasMore = true; // Reset hasMore when resetting articles
      state.currentPage = 1; // Reset currentPage when resetting articles
      state.failureCount = 0; // NEW: Reset failure count
    },
    resetArticleDetail: (state) => {
      state.articleDetail = null;
      state.detailStatus = 'idle';
      state.detailError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchArticles actions
      .addCase(fetchArticles.pending, (state) => {
        state.listStatus = 'loading';
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        const { items, append, page, limit } = action.payload;
        
        if (append && page > 1) {
          state.list = uniqBy([...state.list, ...items], '_id');
        } else {
          state.list = items;
        }
        
        state.listStatus = 'succeeded';
        state.hasMore = items.length === limit; // Update hasMore based on fetched items
        state.currentPage = page; // Update currentPage
        state.failureCount = 0; // NEW: Reset failure count on success
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.listStatus = 'failed';
        state.listError = handleError(action.error, 'fetchArticles');
        state.failureCount += 1; // NEW: Increment failure count
      })
      // Handle fetchArticleDetail actions
      .addCase(fetchArticleDetail.pending, (state) => {
        state.detailStatus = 'loading';
      })
      .addCase(fetchArticleDetail.fulfilled, (state, action) => {
        state.articleDetail = action.payload;
        state.detailStatus = 'succeeded';
        state.detailError = null;
      })
      .addCase(fetchArticleDetail.rejected, (state, action) => {
        state.detailStatus = 'failed';
        state.detailError = handleError(action.error, 'fetchArticleDetail');
      });
  },
});

export const { clearArticles, resetArticles, resetArticleDetail } = articleSlice.actions;

export default articleSlice.reducer;

