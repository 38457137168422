import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ShareIconOutlined,
  BookmarkIconFilled,
  BookmarkIconOutlined,
  PlayArrowIcon,
  ExpandMoreIcon,
  ThumbUpIconOutlined,
  ThumbUpIcon,
  ThumbDownIconOutlined,
  ThumbDownIcon,
  ModeCommentOutlinedIcon
} from '../../../resources/fonts/icons';
import TattleLink from '../../link/link.component';
import classNames from 'classnames';
import styles from '../../button/button.module.scss';
import { getLnk } from '../../../utils/article/article.util';
import { useClickedArticles } from '../../../hooks/useClickedArticles';
import { useShare } from '../../../hooks/useShare';
import useBookmark from '../../../hooks/useBookmark';
import ShareComponent from '../../share/share.component';
import ArticleDropdownMenu from '../../dropDownMenu/articleDropdownMenu/articleDropdownMenu';
import Timestamp from '../../timestamp/timestamp.component';
import './articleItem.component.scss';
import './articleItem.mobile.scss';
import './articleItem.libraryRow.scss';

const ArticleLink = ({ article, showName, disableLink, inTopicPage }) => {
  const { _topic, feed_link } = article;

  if (inTopicPage && feed_link) {
    return (
      <TattleLink
        className="article__action article__action--topic"
        to={feed_link.href}
        target="_blank"
      >
        {feed_link.title}
      </TattleLink>
    );
  } else if (disableLink && showName && feed_link) {
    return (
      <TattleLink
        className="article__action article__action--topic"
        to={feed_link.href}
        target="_blank"
      >
        {feed_link.title}
      </TattleLink>
    );
  } else if (disableLink && _topic?.seo_name) {
    return (
      <TattleLink
        className="article__action article__action--topic"
        to={`/topic/${encodeURIComponent(_topic.seo_name)}`}
      >
        {_topic.label}
      </TattleLink>
    );
  } else if (showName && feed_link) {
    return (
      <TattleLink
        className="article__action article__action--topic"
        to={feed_link.href}
        target="_blank"
      >
        {feed_link.title}
      </TattleLink>
    );
  } else if (_topic?.seo_name) {
    return (
      <TattleLink
        className="article__action article__action--topic"
        to={`/topic/${encodeURIComponent(_topic.seo_name)}`}
      >
        {_topic.label}
      </TattleLink>
    );
  }

  return null;
};

ArticleLink.propTypes = {
  article: PropTypes.shape({
    _topic: PropTypes.object,
    feed_link: PropTypes.object,
  }).isRequired,
  showName: PropTypes.bool.isRequired,
  disableLink: PropTypes.bool,
  inTopicPage: PropTypes.bool,
};

const ArticleItem = React.memo(({
  article,
  showName,
  isClicked,
  isInTopicPage,
  updateBookmark,
  isLibraryRow,
}) => {
  if (!article || typeof article !== 'object' || !article._id) {
    return null;
  }

  const { title, images, _topic, publish_date, feed_type } = article;
  const thumbnail = images?.[0]?.src || _topic?.thumbnail;
  const isYouTubeVideo = feed_type === 'youtube';

  const link = getLnk(article) || '#';
  const isInternalLink = link.indexOf('://') === -1;

  const { markAsClicked } = useClickedArticles();
  const { isSharing, shareOptions, openShare, closeShare } = useShare();
  const { toggleBookmark, bookmarkMap } = useBookmark();

  // Modified click handlers to ignore right-click events
  const handleAuxClick = useCallback((e) => {
    if (e.button === 2) return; // Do nothing on right-click
    markAsClicked(article._id);
    window.open(link, '_blank');
  }, [link, markAsClicked, article._id]);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default anchor navigation
    if (e.button === 2) return; // Do nothing on right-click
    markAsClicked(article._id);
    window.open(link, '_blank');
  };

  const handleBookMarkClick = useCallback(async () => {
    if (updateBookmark) {
      updateBookmark(article._id);
    } else {
      const bookmarkId = bookmarkMap[article._id];
      const isBookmarked = !!bookmarkId;
      try {
        await toggleBookmark(article, bookmarkId, isBookmarked);
      } catch (error) {
        console.error('Error toggling bookmark:', error);
      }
    }
  }, [toggleBookmark, bookmarkMap, updateBookmark, article]);

  const isBookmarked = useMemo(() => !!bookmarkMap[article._id], [bookmarkMap, article._id]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  const handleExpandToggle = () => setIsExpanded(!isExpanded);
  const handleThumbUpToggle = () => {
    setIsLiked(!isLiked);
    if (!isLiked && isDisliked) setIsDisliked(false);
  };
  const handleThumbDownToggle = () => {
    setIsDisliked(!isDisliked);
    if (!isDisliked && isLiked) setIsLiked(false);
  };

  // New flag to disable actions temporarily
  const actionsDisabled = true;

  return (
    <li className={classNames('article__item', { 'library-row': isLibraryRow })}>
      <div className="article__content">
        {isInternalLink ? (
          <TattleLink
            to={link}
            onClick={handleClick}
            onAuxClick={handleAuxClick}
          >
            <div
              className="article__thumbnail"
              style={{ backgroundImage: `url(${thumbnail})` }}
            >
              {isYouTubeVideo && <PlayArrowIcon className="article__play-icon" />}
            </div>
          </TattleLink>
        ) : (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
            onAuxClick={handleAuxClick}
          >
            <div
              className="article__thumbnail"
              style={{ backgroundImage: `url(${thumbnail})` }}
            >
              {isYouTubeVideo && <PlayArrowIcon className="article__play-icon" />}
            </div>
          </a>
        )}
        <div className="article__details">
          <div className="article__meta">
            <ArticleLink 
              article={article}
              showName={showName}
              disableLink
              inTopicPage={isInTopicPage}
            />
            {publish_date && (
              <Timestamp 
                publishDate={publish_date} 
                className="article__action article__action--date" 
              />
            )}
          </div>
          {isInternalLink ? (
            <TattleLink
              to={link}
              onClick={handleClick}
              onAuxClick={handleAuxClick}
            >
              <div className={`article__title ${isClicked ? 'article__title--clicked' : ''}`}>
                {title}
              </div>
            </TattleLink>
          ) : (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
              onAuxClick={handleAuxClick}
            >
              <div className={`article__title ${isClicked ? 'article__title--clicked' : ''}`}>
                {title}
              </div>
            </a>
          )}
          {!isLibraryRow && (
            <div className="article__actions">
              <div className="article__button-container">
                <span
                  className={classNames(styles['btn--icon_wbg'], { [styles.active]: isLiked })}
                  onClick={actionsDisabled ? () => {} : handleThumbUpToggle}
                  aria-label="Thumb Up"
                  aria-disabled={actionsDisabled ? "true" : "false"}
                >
                  {isLiked ? <ThumbUpIcon /> : <ThumbUpIconOutlined />}
                </span>
              </div>
              <div className="article__button-container">
                <span
                  className={classNames(styles['btn--icon_wbg'], { [styles.active]: isDisliked })}
                  onClick={actionsDisabled ? () => {} : handleThumbDownToggle}
                  aria-label="Thumb Down"
                  aria-disabled={actionsDisabled ? "true" : "false"}
                >
                  {isDisliked ? <ThumbDownIcon /> : <ThumbDownIconOutlined />}
                </span>
              </div>
              <div className="article__button-container comment-button">
                <span
                  className={styles['btn--icon_wbg']}
                  onClick={actionsDisabled ? () => {} : (() => {
                    // TODO: implement comment functionality if needed
                  })}
                  aria-label="Comment"
                  aria-disabled={actionsDisabled ? "true" : "false"}
                >
                  <ModeCommentOutlinedIcon />
                </span>
              </div>
              <div className="article__button-container share-button">
                <span
                  className={styles['btn--icon_wbg']}
                  onClick={() => {
                    openShare({
                      _id: article._id,
                      title: article.title,
                      content: article.content_text,
                      url: getLnk(article),
                    });
                  }}
                  aria-label="Share Article"
                >
                  <ShareIconOutlined />
                </span>
              </div>
              <div className="article__button-container bookmark-button">
                <span
                  className={classNames(styles['btn--icon_wbg'], { [styles.active]: isBookmarked })}
                  onClick={handleBookMarkClick}
                  aria-label={isBookmarked ? "Remove Bookmark" : "Add Bookmark"}
                >
                  {isBookmarked ? <BookmarkIconFilled /> : <BookmarkIconOutlined />}
                </span>
              </div>
              <div className="article__button-container expand-button">
                <span
                  className={classNames(styles['btn--icon_wbg'], { 'rotate': isExpanded })}
                  onClick={handleExpandToggle}
                  aria-label="Expand More"
                >
                  <ExpandMoreIcon />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLibraryRow && (
        <ArticleDropdownMenu
          article={article}
          openShare={openShare}
          isBookmarked={isBookmarked}
          handleBookMarkClick={handleBookMarkClick}
        />
      )}
      <ShareComponent
        isVisible={isSharing}
        handleShareClose={closeShare}
        type="article"
        {...shareOptions}
      />
      <div className={`article__content-text ${isExpanded ? 'expanded' : ''}`}>
        {article.content_text}... 
        <TattleLink to={link} target="_blank" className="article__learn-more">
          Learn more
        </TattleLink>
      </div>
    </li>
  );
});

ArticleItem.propTypes = {
  article: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.array,
    publish_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    feed_link: PropTypes.object,
    _topic: PropTypes.object,
    content_text: PropTypes.string,
  }).isRequired,
  showName: PropTypes.bool.isRequired,
  isClicked: PropTypes.bool,
  isInTopicPage: PropTypes.bool,
  updateBookmark: PropTypes.func,
  isLibraryRow: PropTypes.bool,
};

ArticleItem.defaultProps = {
  isClicked: false,
  updateBookmark: null,
  isLibraryRow: false,
};

export default ArticleItem;