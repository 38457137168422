import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';
import './link.component.css';

/**
 * Link component to handle both internal and external links
 *
 */
const TattleLink = ({ to = '/', children, className = '', target, ...rest }) => {
  const isInternal = to.indexOf('://') === -1 && !target; // Update condition to exclude when target is present

  className = `tattle-link${className ? ` ${className}` : ''}`;
  
  try {
    to = decodeURI(to); // Decodes the URI if possible
  } catch (error) {
    console.warn('Error decoding URI:', error);
  }

  const handleMouseEvent = (e) => {
    // Handle middle mouse button click (button === 1)
    if (e.button === 1) {
      e.preventDefault();
      window.open(isInternal ? `${window.location.origin}${to}` : to, '_blank');
    }
  };

  const handleClick = () => {
    console.log(`TattleLink clicked: navigating to ${to}`);
  };

  if (isInternal) {
    return (
      <ReactLink 
        className={className} 
        to={to} 
        onClick={handleClick}
        onMouseDown={handleMouseEvent}
        {...rest}
      >
        {children}
      </ReactLink>
    );
  } else {
    return (
      <a 
        href={to} 
        className={className} 
        target={target || '_blank'} 
        rel={target === '_blank' ? 'noopener noreferrer' : undefined} 
        onClick={handleClick}
        onMouseDown={handleMouseEvent}
        {...rest}
      >
        {children}
      </a>
    );
  }
};

TattleLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  target: PropTypes.string
};

export default TattleLink;
