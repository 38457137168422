// src/containers/root.container.js

import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'; // Ensure useDispatch is imported
import config from '../../config';
import NavigationContainer from '../navigation/navigation.container';
import UpdateEmail from '../../pages/settings-page/account/updateAccount/updateEmail.account'; // Add this import
import SidebarContainer from '../sidebar/sidebar.container'; // Import SidebarContainer
import './root.container.css';
import '../../resources/scss/global.css';

const DEFAULT_THEME = createTheme();


// Lazy-loaded pages
const TrendingPage = lazy(() => import('../../pages/trending-page/trending.page'));
const CategoryPage = lazy(() => import('../../pages/explore-page/categoryPage/category.page'));
const ExplorePage = lazy(() => import('../../pages/explore-page/explorePage/explore.page'));
const TopicPage = lazy(() => import('../../pages/topic-page/topic.page'));
const PolicyPage = lazy(() => import('../../pages/policies-page/policies.page'));
const HomePage = lazy(() => import('../../pages/home-page/home.page'));
const LibraryPage = lazy(() => import('../../pages/library-page/libraryPage/library.page'));
const ViewLibrarySectionPage = lazy(() => import('../../pages/library-page/librarySectionPage/librarySectionPage'));
const BotPage = lazy(() => import('../../pages/bot-page/bot.page'));
const ErrorPage = lazy(() => import('../../pages/error-page/error.page'));
const SettingsPage = lazy(() => import('../../pages/settings-page/settingsPage/settings.page'));
const AccountSettingsPage = lazy(() => import('../../pages/settings-page/account/account.settings.page'));
const MembershipSettingsPage = lazy(() => import('../../pages/settings-page/membership/membership.page'));
const BotSettingsPage = lazy(() => import('../../pages/settings-page/bot/bot.settings.page')); // Add this import
const HelpLegalSettingsPage = lazy(() => import('../../pages/settings-page/help-legal/help-legal.page'));
const UpdateUsername = lazy(() => import('../../pages/settings-page/account/updateAccount/updateUsername.account'));
const UpdatePhone = lazy(() => import('../../pages/settings-page/account/updateAccount/updatePhone.account'));
const UpdatePassword = lazy(() => import('../../pages/settings-page/account/updateAccount/updatePassword.account'));
const ForgotPassword = lazy(() => import('../../pages/forgotPassword/forgotPassword.page'));
const DiscordOauth2Page = lazy(() => import('../../pages/oauth2-page/discord/discord.page'));
const GoogleOauth2Page = lazy(() => import('../../pages/oauth2-page/google/google.page'));

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate replace to="/trending" />;
};

// GA Listener for tracking
const GAListener = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};


const AppRoutes = React.memo(() => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const background = location.state && location.state.backgroundLocation;

  const publicRoutes = [
    { path: '/', element: <Navigate replace to="/trending" /> },
    { path: '/dashboard', element: <Navigate replace to="/trending" /> },
    { path: '/trending', element: <TrendingPage /> },
    { path: '/explore', element: <ExplorePage /> },
    { path: '/explore/:category', element: <CategoryPage /> },
    { path: '/topic', element: <Navigate replace to="/explore" /> },
    { path: '/topic/:id', element: <TopicPage /> },
    { path: '/library', element: <LibraryPage /> },
    { path: '/library/bookmarks', element: <PrivateRoute><ViewLibrarySectionPage type="bookmarks" /></PrivateRoute> },
    { path: '/library/history', element: <PrivateRoute><ViewLibrarySectionPage type="history" /></PrivateRoute> },
    { path: '/bot', element: <BotPage /> },
    { path: '/termsofservice', element: <PolicyPage page="termsofservice" /> },
    { path: '/privacypolicy', element: <PolicyPage page="privacypolicy" /> },
    { path: '/forgotPassword', element: <ForgotPassword /> },
    { path: '/oauth2/discord', element: <DiscordOauth2Page /> },
    { path: '/oauth2/google', element: <GoogleOauth2Page /> },
    { path: '*', element: <ErrorPage /> },
  ];

  const privateRoutes = [
    { path: '/home', element: <HomePage /> },
    { path: '/settings', element: <SettingsPage /> },
    { path: '/settings/account', element: <AccountSettingsPage /> },
    { path: '/settings/updateUsername', element: <UpdateUsername /> },
    { path: '/settings/updatePhone', element: <UpdatePhone /> },
    { path: '/settings/updatePassword', element: <UpdatePassword /> },
    { path: '/settings/updateEmail', element: <UpdateEmail /> },
    { path: '/settings/membership', element: <MembershipSettingsPage /> },
    { path: '/settings/bot', element: <BotSettingsPage /> }, // Add this route
    { path: '/settings/help', element: <HelpLegalSettingsPage /> },
  ];

  return (
    <Suspense fallback={null}>
      <Routes location={background || location}>
        {publicRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        {privateRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ))}
      </Routes>
    </Suspense>
  );
});

const RootContainer = () => {
  const dispatch = useDispatch();


  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_API_KEY);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    
    // dispatch(fetchProfileSubscriptionsAndBookmarks()); // Remove this line
  }, [dispatch]);

  return (
        <div className="root-container">
          <Router>
            <ThemeProvider theme={DEFAULT_THEME}>
              <div className="root-container_inner">
                <NavigationContainer />
                <div className="body">
                  <div id="top-of-page"></div>
                  <GAListener />
                  <AppRoutes />
                </div>
                <SidebarContainer />
              </div>
            </ThemeProvider> 
          </Router>
        </div>
  );
};

export default RootContainer;