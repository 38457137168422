// src/utils/errors/errorHandler.js

import AuthService from '../../services/auth.service';

/**
 * Centralized error handling function.
 */
export const handleError = (error, context) => {
  let errorMessage = 'An unknown error occurred';

  if (error.response) {
    if (error.response.data?.message) { // Ensure server message is used if available
      errorMessage = error.response.data.message;
    } else if (error.response.status === 401) {
      AuthService.handleLogout();
      errorMessage = 'Authentication failed'; // Add this line
    } else {
      errorMessage = error.response.statusText || errorMessage;
    }
  } else if (error.request) {
    errorMessage = 'No response received from server';
  } else if (error.message) {
    errorMessage = error.message;
  }

  console.error(`Error in ${context}: ${errorMessage}`, {
    error,
    context,
  });

  return errorMessage;
};
