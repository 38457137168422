import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import './footer.component.scss'
import { TattleLink } from '../index'
import config from '../../config'

/**
 * Footer component
 * @param props
 * @constructor
 */
const Footer = (props) => {
  const { version } = props

  return (
    <div className='footer'>
      <div className='footer_item footer_copy'>
        &copy; Tattle Media, Inc. {moment.utc(new Date()).format('YYYY')} · <span className='version'>Version {version}</span>
      </div>
      <div className='footer_item footer_links'>
        <TattleLink to={'/termsofservice'} className={'terms-of-use'} target="_blank" rel="noopener noreferrer">
          <span className='terms-of-use'>Terms of Use</span>
        </TattleLink>
        <TattleLink to={'/privacypolicy'} className={'privacy-policy'} target="_blank" rel="noopener noreferrer">
          <span className='privacy-policy'>Privacy Policy</span>
        </TattleLink>
      </div>
    </div>
  )
}

Footer.propTypes = {
  version: PropTypes.string
}

Footer.defaultProps = {
  version: config.APP_VERSION
}

export default Footer
