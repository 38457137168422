import { useDispatch } from 'react-redux';
import { updateSearchQuery, clearSearchQuery } from '../reducers/searchSlice';
import { searchBookmarks } from '../reducers/bookmarksSlice';
import { searchHistory } from '../reducers/historySlice';
import { handleError } from '../utils/errors/errorHandler';
import useDebounce from './useDebounce';

function useSearch() {
  const dispatch = useDispatch();
  const debouncedSearch = useDebounce((term) => {
    try {
      dispatch(updateSearchQuery(term));
      dispatch(searchBookmarks(term));
      dispatch(searchHistory(term));
    } catch (error) {
      handleError(error, 'useSearch.performSearch');
    }
  }, 300);

  const performSearch = (term) => {
    debouncedSearch(term.trim());
  };

  const clearSearch = () => {
    dispatch(clearSearchQuery());
  };

  return { performSearch, clearSearch };
}

export default useSearch;