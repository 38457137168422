import React from 'react';
import Button from '../../button/button.component';
import './OAuthLoginButton.component.css';
import config from '../../../config';

// Add helper function for generating state
const generateState = () => Math.random().toString(36).substring(2) + Date.now().toString(36);

const supportedProviders = new Set(['discord', 'apple', 'google']);

const providerImages = {
  discord: 'https://storage.googleapis.com/tattle-public-images/site/oauth-icons/discord.png',
  apple: 'https://storage.googleapis.com/tattle-public-images/site/oauth-icons/apple.png',
  google: 'https://storage.googleapis.com/tattle-public-images/site/oauth-icons/google.png',
};

const OAuthLoginButton = ({ oAuthProvider = '' }) => {
  if (oAuthProvider === '' || !supportedProviders.has(oAuthProvider)) {
    throw Error(
      `Invalid social platform selected, please select from: ${Array.from(supportedProviders)}`
    );
  }

  const handleButtonClick = () => {
    try {
      // Generate state, store in sessionStorage
      const state = generateState();
      sessionStorage.setItem('oauthState', state);
      let url = '';

      switch (oAuthProvider) {
        case 'discord':
          url = config.DISCORD_OAUTH2_URL + `&state=${state}`;
          // Instead of opening a popup, redirect in same window
          window.location.href = url;
          break;
        case 'google':
          if (!config.GOOGLE_OAUTH2_URL) {
            console.error('Google OAuth URL not configured');
            return;
          }
          url = config.GOOGLE_OAUTH2_URL + `&state=${state}`;
          window.location.href = url;
          break;
        case 'apple':
          console.warn('Apple login not implemented');
          break;
        default:
          console.error('Unknown provider:', oAuthProvider);
          break;
      }
    } catch (error) {
      console.error('OAuth redirect error:', error);
    }
  };

  const label = oAuthProvider.charAt(0).toUpperCase() + oAuthProvider.slice(1);

  return (
    <Button
      className="oauth-login-button"
      label={`Continue with ${label}`}
      image={providerImages[oAuthProvider]}
      onClick={handleButtonClick}
    />
  );
};

export default OAuthLoginButton;
