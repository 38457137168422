import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, FormField, TattleLink } from '../../index'
import OAuthLoginButton from '../OAuthLoginButton/OAuthLoginButton.component'
import '../authentication.component.css'
import { validateField } from '../../../utils/form/validation.util';

/**
 * Login Form component
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LoginForm = ({
  identifier,
  password,
  onLoginClick,
  handleLoginFieldChange,
  handleViewSwitch,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const handleLoginButtonClick = useCallback(() => {
    if (isFormValid && onLoginClick) {
      onLoginClick({
        isValid: isFormValid, // Add isValid flag
        values: { 
          identifier,
          password,
        },
      });
    }
  }, [isFormValid, onLoginClick, identifier, password]);

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && isFormValid) {
      handleLoginButtonClick();
    }
  }, [isFormValid, handleLoginButtonClick]);

  const handleChange = useCallback((event, value, validation) => {
    const { name } = event.target;
    handleLoginFieldChange(event, value);

    // Update form validity
    const identifierValid = name === 'identifier' ? validation.isValid : identifier !== '';
    const passwordValid = name === 'password' ? validation.isValid : password !== '';

    setIsFormValid(identifierValid && passwordValid);
  }, [handleLoginFieldChange, identifier, password]);

  useEffect(() => {
    const identifierValid = validateField('identifier', identifier, null, true).isValid;
    const passwordValid = validateField('password', password, null, true).isValid;
    setIsFormValid(identifierValid && passwordValid);
  }, [identifier, password]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault(); // Prevent default form submission
    handleLoginButtonClick();
  }, [handleLoginButtonClick]);

  return (
    <section className='authentication__inner'>
      <h3 className='authentication__title'>Sign In</h3>
      <div className='authentication__disclaimer'>By continuing, you agree to our <TattleLink to="/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</TattleLink> and <TattleLink to="privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</TattleLink></div>
      <OAuthLoginButton oAuthProvider="google" />
      <div className="login-divider">
          <span>OR</span>
      </div>
      <form onSubmit={handleSubmit}> {/* Wrap form fields in a form */}
        <div className='authentication__form'>
          <FormField
            size='full'
            id='login_identifier'
            type='text'
            name='identifier'
            value={identifier}
            label='Username, email, or phone'
            required={true}
            onChange={handleChange}
            onKeyDown={onKeyDown}
          />
          <FormField
            size='full'
            type='password'
            id='login_password'
            name='password'
            value={password}
            label='Password'
            required={true}
            onChange={handleChange}
            onKeyDown={onKeyDown}
          />
          <div className="forgot-password-section">
            <span 
              className='authentication__link' 
              onClick={() => handleViewSwitch('forgotPassword')}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === 'Enter' && handleViewSwitch('forgotPassword')}
            >
              Forgot Password?
            </span>
          </div>
          <Button
            type="button"
            label='Sign In' // Ensure using the label prop that renders with btn__label
            onClick={handleLoginButtonClick}
            disabled={!isFormValid}
            variant='blue__large'
            aria-disabled={!isFormValid}
          />
        </div>
      </form>
      <div className='authentication__message'>
        <div className="signup-section">
          <span>New to Tattle?</span>
          <span 
            className='authentication__link' 
            onClick={() => handleViewSwitch('registration')}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && handleViewSwitch('registration')}
          >
            Sign up
          </span>
        </div>
      </div>
    </section>
  )
}

LoginForm.propTypes = {
  identifier: PropTypes.string,
  password: PropTypes.string,
  onLoginClick: PropTypes.func.isRequired,
  handleLoginFieldChange: PropTypes.func.isRequired,
  handleViewSwitch: PropTypes.func.isRequired,
}

export default React.memo(LoginForm)