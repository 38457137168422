// src/reducers/bookmarksSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import queryString from 'query-string';
import { handleError } from '../utils/errors/errorHandler'; // Imported handleError

/** Fetch Bookmarks */
export const fetchBookmarks = createAsyncThunk(
  'bookmarks/fetchBookmarks',
  async (params = {}, { rejectWithValue }) => { // Provide default value for params
    try {
      const query = queryString.stringify(params);
      const response = await api.get(`/private/bookmark?${query}`);  // Ensure API accepts profileId if needed
      return response.data.data;
    } catch (error) {
      const errorMessage = handleError(error, 'fetchBookmarks');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Create Bookmark */
export const createBookmark = createAsyncThunk(
  'bookmarks/createBookmark',
  async (bookmarkData, { rejectWithValue }) => {
    try {
      const response = await api.post(`/private/bookmark`, bookmarkData);  // ✓ Matches POST ${API_URL}/private/bookmark
      return response.data.data;
    } catch (error) {
      const errorMessage = handleError(error, 'createBookmark');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Delete Bookmark */
export const deleteBookmark = createAsyncThunk(
  'bookmarks/deleteBookmark',
  async (bookmarkId, { rejectWithValue }) => {
    try {
      await api.delete(`/private/bookmark/${bookmarkId}`);  // ✓ Matches DELETE ${API_URL}/private/bookmark/${id}
      return bookmarkId;
    } catch (error) {
      const errorMessage = handleError(error, 'deleteBookmark');
      return rejectWithValue(errorMessage);
    }
  }
);


// Initial state
const initialState = {
  bookmarks: [],
  bookmarksByArticleId: {}, // Add this to track bookmarks by article ID
  filteredBookmarks: [], // Ensure this is initialized
  status: 'idle',
  error: null,
};

// Bookmarks slice
const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    resetBookmarksState: () => initialState,
    setFilteredBookmarks: (state, action) => {
      state.filteredBookmarks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Bookmarks
      .addCase(fetchBookmarks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookmarks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;

        const uniqueBookmarks = [];
        const ids = new Set();

        action.payload.forEach(bookmark => {
          if (!ids.has(bookmark._id)) {
            ids.add(bookmark._id);
            uniqueBookmarks.push(bookmark);

            const articleId = bookmark.articleId || bookmark._article || (bookmark.article && bookmark.article._id);
            if (articleId) {
              state.bookmarksByArticleId[articleId] = bookmark._id;
            }
          }
        });

        state.bookmarks = uniqueBookmarks;
        state.filteredBookmarks = uniqueBookmarks; // Populate filteredBookmarks
      })
      .addCase(fetchBookmarks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = handleError(action.error, 'fetchBookmarks');
      })
      // Create Bookmark
      .addCase(createBookmark.fulfilled, (state, action) => {
        const bookmark = action.payload;
        const articleId = bookmark.articleId || bookmark._article || (bookmark.article && bookmark.article._id);

        if (bookmark && bookmark._id && articleId) {
          // Add bookmark to bookmarks array
          state.bookmarks.push(bookmark);

          // Ensure bookmarksByArticleId is initialized
          if (!state.bookmarksByArticleId) {
            state.bookmarksByArticleId = {};
          }

          // Update bookmarksByArticleId map
          state.bookmarksByArticleId[articleId] = bookmark._id;
        }
      })
      .addCase(createBookmark.rejected, (state, action) => {
        state.error = handleError(action.error, 'createBookmark');
      })
      // Delete Bookmark
      .addCase(deleteBookmark.fulfilled, (state, action) => {
        const bookmarkId = action.payload;
        // Remove bookmark from bookmarks array
        state.bookmarks = state.bookmarks.filter(b => b._id !== bookmarkId);
        // Remove entry from bookmarksByArticleId map
        Object.keys(state.bookmarksByArticleId).forEach(key => {
          if (state.bookmarksByArticleId[key] === bookmarkId) {
            delete state.bookmarksByArticleId[key];
          }
        });
      })
      .addCase(deleteBookmark.rejected, (state, action) => {
        state.error = handleError(action.error, 'deleteBookmark');
      });
  },
});

// Selectors
export const selectBookmarks = (state) => state.bookmarks?.bookmarks || [];
export const selectBookmarksByArticleId = (state) => state.bookmarks?.bookmarksByArticleId || {};

export const selectBookmarkByArticleId = (state, articleId) => {
  return state.bookmarks.bookmarksByArticleId[articleId] || null;
};

export const selectFilteredBookmarks = (state) => {
  const filtered = state.bookmarks?.filteredBookmarks;
  const allBookmarks = state.bookmarks?.bookmarks;

  if (Array.isArray(filtered) && filtered.length > 0) {
    return filtered;
  }

  return Array.isArray(allBookmarks) ? allBookmarks : [];
};

export const searchBookmarks = (searchTerm) => (dispatch, getState) => {
  const { bookmarks } = getState().bookmarks;
  if (!Array.isArray(bookmarks)) return;

  const filtered = bookmarks.filter((bookmark) =>
    bookmark.article?.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  dispatch(setFilteredBookmarks(filtered));
};

// Export actions and reducer
export const { resetBookmarksState, setFilteredBookmarks } = bookmarksSlice.actions;
export default bookmarksSlice.reducer;
