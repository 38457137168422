import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormField } from '../../index';
import { TattleLink } from '../../index';
import '../authentication.component.css';
import OAuthLoginButton from '../OAuthLoginButton/OAuthLoginButton.component';
import { validateField } from '../../../utils/form/validation.util';

const RegisterForm = ({
  email,
  password,
  confirmPassword,
  username,
  phone,
  onRegisterClick,
  onFieldChange,
  handleViewSwitch,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const handleRegisterButtonClick = useCallback(() => {
    if (isFormValid && onRegisterClick) {
      onRegisterClick({
        isValid: isFormValid, 
        values: { 
          email,
          password,
          confirmPassword,
          username,
          phone,
        },
      });
    }
  }, [isFormValid, onRegisterClick, email, password, confirmPassword, username, phone]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && isFormValid) {
        handleRegisterButtonClick();
      }
    },
    [isFormValid, handleRegisterButtonClick]
  );

  const handleChange = useCallback(
    (event, value ) => {
      onFieldChange(event, value);

      // Re-check validity of all fields
      const emailValid = validateField('email', email, null, true).isValid;
      const usernameValid = validateField('username', username, null, true).isValid;
      const passwordValid = validateField('password', password, null, true).isValid;
      const confirmPasswordValid = validateField('confirmPassword', confirmPassword, password, true).isValid;

      setIsFormValid(emailValid && usernameValid && passwordValid && confirmPasswordValid);
    },
    [onFieldChange, email, username, password, confirmPassword]
  );

  useEffect(() => {
    const emailValid = validateField('email', email, null, true).isValid;
    const usernameValid = validateField('username', username, null, true).isValid;
    const passwordValid = validateField('password', password, null, true).isValid;
    const confirmPasswordValid = validateField('confirmPassword', confirmPassword, password, true).isValid;
    setIsFormValid(emailValid && usernameValid && passwordValid && confirmPasswordValid);
  }, [email, username, password, confirmPassword]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault(); // Prevent default form submission
    handleRegisterButtonClick();
  }, [handleRegisterButtonClick]);

  return (
    <section className='authentication__inner'>
      <h3 className='authentication__title'>Welcome to Tattle!</h3>
      <div className='authentication__disclaimer'>
        By continuing, you agree to our <TattleLink to="/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</TattleLink> and <TattleLink to="privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</TattleLink>
      </div>
      <OAuthLoginButton oAuthProvider="google" />
      <div className="login-divider">
        <span>OR</span>
      </div>
      <form onSubmit={handleSubmit}> {/* Wrap form fields in a form */}
        <div className='authentication__form'>
          <FormField
            size="full"
            id="register_email"
            type="email"
            name="email"
            value={email}
            label="Email"
            required={true}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            autoComplete="new-email"
          />
          <FormField
            size="full"
            id="register_username"
            name="username"
            type="text"
            value={username}
            label="Username"
            required={true}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            autoComplete="new-username"
          />
          <FormField
            size="full"
            type="password"
            id="register_password"
            name="password"
            value={password}
            label="New password"
            required={true}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            autoComplete="new-password"
          />
          <FormField
            size="full"
            type="password"
            id="register_confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            label="Confirm password"
            required={true}
            matchValue={password} // Pass password for validation
            onChange={handleChange}
            onKeyDown={onKeyDown}
            autoComplete="new-password"
          />
          <div className="password-requirements">
            Your password must be at least 8 characters long, include a number, an uppercase letter, and a lowercase letter.
          </div>
          <Button
            type="button" // Set type to button to prevent form submission
            label='Sign Up'
            onClick={handleRegisterButtonClick}
            disabled={!isFormValid}
            variant='blue__large'
            aria-disabled={!isFormValid} // Add ARIA attribute for accessibility
          />
        </div>
      </form>
      <div className='authentication__message'>
        <div className="login-section">
          <span>Already have an account?</span>
          <span 
            className='authentication__link' 
            onClick={() => handleViewSwitch('login')}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && handleViewSwitch('login')}
          >
            Sign In
          </span>
        </div>
      </div>
    </section>
  );
};

RegisterForm.propTypes = {
  email: PropTypes.string, // Assuming registration still uses 'email'
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  username: PropTypes.string,
  phone: PropTypes.string,
  onRegisterClick: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired, // Rename this prop type
  handleViewSwitch: PropTypes.func.isRequired,
};

export default React.memo(RegisterForm);
