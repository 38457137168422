
import { useEffect, useRef } from 'react';
import useInfiniteScroll from './useInfiniteScroll.util';

const useScrollHandler = ({ fetchMore, hasMore, threshold = 0.1, rootMargin = '100px', debounceTime = 150 }) => {
  const scrollTriggerRef = useRef(null);

  useInfiniteScroll({
    fetchItems: hasMore ? fetchMore : null,
    threshold,
    rootMargin,
    debounceTime,
    targetRef: scrollTriggerRef.current,
    enabled: true,
  });

  useEffect(() => {
    return () => {
      if (scrollTriggerRef.current) {
        scrollTriggerRef.current.style.display = 'none';
      }
    };
  }, []);

  return scrollTriggerRef;
};

export default useScrollHandler;