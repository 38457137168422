// ScrollableNav.js
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './scrollableNav.css';
import { ArrowBackIcon, ArrowForwardIcon } from '../../resources/fonts/icons';
import useScrollableNav from '../../hooks/useScrollableNav';

/**
 * ScrollableNav Component
 * A reusable navigation bar with scroll buttons, drag functionality, and wheel scrolling.
 */
const ScrollableNav = ({
  children,
  className = '',
  leftButtonClass = '',
  rightButtonClass = '',
  ...props
}) => {
  const scrollBarRef = useRef(null);

  const {
    handleScroll,
    handleStartDrag,
    startContinuousScroll,
    stopContinuousScroll,
    showLeftButton,
    showRightButton,
  } = useScrollableNav(scrollBarRef, { useVerticalScrollWheel: true });

  // Refs to manage hold-to-scroll timeout
  const scrollTimeoutRef = useRef(null);
  const isHoldingRef = useRef(false);

  // Handle press start (mouse down or touch start)
  const handlePressStart = (direction) => { // Added 'direction' parameter
    // Start a timeout to determine if the button is held down
    scrollTimeoutRef.current = setTimeout(() => {
      isHoldingRef.current = true;
      startContinuousScroll(direction);
    }, 300); // 300ms delay before starting continuous scroll
  };

  // Handle press end (mouse up or touch end)
  const handlePressEnd = (direction) => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = null;
    }

    if (isHoldingRef.current) {
      // If it was a hold action, stop continuous scroll
      isHoldingRef.current = false;
      stopContinuousScroll();
    } else {
      // If it was a quick click, perform a single scroll
      handleScroll(direction);
    }
  };

  // Handle mouse leave or touch cancel to stop scrolling
  const handlePressLeave = () => { // Removed 'direction' parameter
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = null;
    }

    if (isHoldingRef.current) {
      isHoldingRef.current = false;
      stopContinuousScroll();
    }
  };

  return (
    <div className={`scrollable-nav-container ${className}`}>
      {/* Left Scroll Button */}
      {showLeftButton && (
        <button
          className={`scrollable-nav-buttons left ${leftButtonClass} ${
            showLeftButton ? 'visible' : 'hidden'
          }`}
          onMouseDown={() => handlePressStart('left')}
          onMouseUp={() => handlePressEnd('left')}
          onMouseLeave={handlePressLeave} // Removed arrow function and 'direction' argument
          onTouchStart={() => handlePressStart('left')}
          onTouchEnd={() => handlePressEnd('left')}
          onTouchCancel={handlePressLeave} // Removed arrow function and 'direction' argument
          aria-label="Scroll Left"
        >
          <ArrowBackIcon style={{ color: '#ffffff' }} />
        </button>
      )}

      {/* Scrollable Content */}
      <div
        className="scrollable-nav"
        ref={scrollBarRef}
        onMouseDown={handleStartDrag}
        onTouchStart={handleStartDrag}
        onDragStart={(e) => e.preventDefault()}
        style={{
          userSelect: 'none',
        }}
        {...props}
      >
        {children}
      </div>

      {/* Right Scroll Button */}
      {showRightButton && (
        <button
          className={`scrollable-nav-buttons right ${rightButtonClass} ${
            showRightButton ? 'visible' : 'hidden'
          }`}
          onMouseDown={() => handlePressStart('right')}
          onMouseUp={() => handlePressEnd('right')}
          onMouseLeave={handlePressLeave} // Removed arrow function and 'direction' argument
          onTouchStart={() => handlePressStart('right')}
          onTouchEnd={() => handlePressEnd('right')}
          onTouchCancel={handlePressLeave} // Removed arrow function and 'direction' argument
          aria-label="Scroll Right"
        >
          <ArrowForwardIcon style={{ color: '#ffffff' }} />
        </button>
      )}
    </div>
  );
};

ScrollableNav.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  leftButtonClass: PropTypes.string,
  rightButtonClass: PropTypes.string,
};

export default ScrollableNav;
