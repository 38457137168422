import { useState, useEffect, useRef } from 'react';

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const blocking = useRef(false);
  const prevScrollY = useRef(window.scrollY);
  const scrollDistance = useRef(0);

  useEffect(() => {
    // Only track scroll on mobile
    if (window.innerWidth > 600) {
      return;
    }

    const THRESHOLD = 50; // Increased from 10
    const TIMEOUT = 100;
    const REQUIRED_SCROLL_DISTANCE = 100; // New: minimum scroll distance to trigger

    const updateScrollDirection = () => {
      const currentScrollY = window.scrollY;
      const diff = currentScrollY - prevScrollY.current;

      if (!blocking.current && Math.abs(diff) > THRESHOLD) {
        scrollDistance.current += diff;

        if (Math.abs(scrollDistance.current) > REQUIRED_SCROLL_DISTANCE) {
          blocking.current = true;
          
          if (currentScrollY > 0 && currentScrollY < document.documentElement.scrollHeight - window.innerHeight) {
            setScrollDirection(scrollDistance.current > 0 ? 'down' : 'up');
          }
          
          scrollDistance.current = 0;
          setTimeout(() => {
            blocking.current = false;
          }, TIMEOUT);
        }
        
        prevScrollY.current = currentScrollY;
      }
    };

    const handleScroll = () => {
      if (!blocking.current) {
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Always return 'up' for desktop
  return window.innerWidth > 600 ? 'up' : scrollDirection;
};
