// src/index.js

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import RootContainer from './containers/root/root.container';
import { initializeAuth } from './reducers/authSlice';

const AppInitializer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAuth());
    window.scrollTo(0, 0);
  }, [dispatch]);

  return <RootContainer />;
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppInitializer />
    </PersistGate>
  </Provider>
);
