import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormField } from '../../index';
import { validateField } from '../../../utils/form/validation.util';
import '../authentication.component.css';

/**
 * Forgot Password Form component
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ForgotPasswordForm = ({
  onForgotPasswordClick,
}) => {
  const [identifier, setIdentifier] = useState('');
  const [isValid, setIsValid] = useState(false); // Add local form validity state

  const handleFieldChange = useCallback((event, value) => {
    const { name } = event.target;
    if (name === 'identifier') {
      setIdentifier(value);
    }
  }, []);

  useEffect(() => {
    const identifierValid = validateField('identifier', identifier, null, true).isValid;
    setIsValid(identifierValid);
  }, [identifier]);

  const handleForgotPasswordClick = useCallback(() => {
    if (isValid && onForgotPasswordClick) {
      // Pass both identifier and isValid flag
      onForgotPasswordClick({ identifier, isValid });
    }
  }, [isValid, onForgotPasswordClick, identifier]);

  return (
    <section className='authentication__inner'>
      <div className='forgot-password-header'>
        <h3 className='authentication__title'>
          Forgot Password
        </h3>
      </div>
      <div className='authentication__disclaimer'>
        We'll send you an email with a link to reset your password.
      </div>
      <div className='authentication__form'>
        <FormField
          size='full'
          id='forgot_password_identifier'
          type='text'
          name='identifier'
          value={identifier}
          label='Username, email, or phone'
          required={true}
          onChange={handleFieldChange}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            label='Submit'
            variant="blue"
            onClick={handleForgotPasswordClick}
            disabled={!isValid}
          />
        </div>
      </div>
    </section>
  );
};

ForgotPasswordForm.propTypes = {
  handleViewSwitch: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
};

export default React.memo(ForgotPasswordForm);
