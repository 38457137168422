// src/components/NewArticlesIndicator/NewArticlesIndicator.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestArticleSince } from '../../reducers/articleSlice'; // Import refetch action
import { jumpToTop } from '../jumpToTop/jumpToTop'; // Import the new jumpToTop function
import { ArrowUpwardIcon } from '../../resources/fonts/icons'; // new import for upward icon
import './newArticlesIndicator.component.scss';

// Update the component signature to accept a topicId prop
export default function NewArticlesIndicator({ isPrivate = false, pageType: explicitPageType, onRefresh, topicId: topicIdProp, navbarVisible }) { // Accept new onRefresh prop
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles.list);
  const status = useSelector((state) => state.articles.listStatus);
  
  // Get all filter values and subscriptions from Redux store
  const filters = useSelector((state) => state.filters);
  const activeTag = useSelector((state) => state.tag.activeTag);
  const subscriptions = useSelector((state) => state.subscriptions.subscriptions);
  
  // Temporarily set showButton to true for development
  const [showButton, setShowButton] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // new state for scroll
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600); // new state for mobile detection

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    // initial check already done by state initializer
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 0);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (showButton || status === 'loading' || !articles.length) return;

      try {
        console.log('NewArticlesIndicator Check:', {
          isPrivate,
          currentFilters: filters,
          activeTag,
        });
        
        // Determine page type using explicitPageType; if it's 'trending', treat it as 'category'
        let pageType = explicitPageType;
        if(pageType === 'trending') {
          pageType = 'category';
        }
        if (!pageType) {
          if (isPrivate) {
            pageType = 'home';
          } else if (filters.topicId) {
            pageType = 'topic';
          } else {
            pageType = 'category';
          }
        }

        console.log('Checking for new articles with:', { pageType });
        // Build the payload based on filters and pageType
        const payload = {
          lastDate: articles[0].publish_date,
          pageType
        };
        if (filters.search) {
          payload.search = filters.search; // ensure search param is set
        }
        // Always include feed_type if activeTag !== 'All'
        if (activeTag && activeTag !== 'All') {
          payload.feed_type = activeTag.toLowerCase();
        }
        // For topic page, send the topic id from filters or the passed prop
        if (pageType === 'topic' && (filters.topicId || topicIdProp)) {
          payload.topicId = filters.topicId || topicIdProp;
        } else if (pageType === 'home') {
          // Only pass a single topicId if it's not empty
          if (filters.topicId && filters.topicId !== '') {
            payload.topicId = filters.topicId;
          } else if (subscriptions && subscriptions.length) {
            payload.subscribedTopics = subscriptions
              .map((s) => s.topic._id)
              .join(',');
          }
        } else if (pageType === 'category') { // For trending page use
          if (filters.category) {
            payload.category = filters.category; 
          }
        }

        // Expect the backend to only return a hasNew flag
        const result = await dispatch(fetchLatestArticleSince(payload)).unwrap();
        console.log('Latest article response:', result);

        if (result && result.hasNew) {
          setShowButton(true);
          clearInterval(intervalId); // Stop further checks once indicator appears
        }
      } catch (error) {
        console.error('Error checking for new articles:', error);
      }
    }, 180000); // 2 minutes

    return () => clearInterval(intervalId);
  }, [
    dispatch,
    articles,
    showButton,
    status,
    filters.category,
    filters.topicId,
    filters.search, // dependency added for search
    activeTag,
    isPrivate,
    explicitPageType,
    subscriptions
  ]);

  const handleLoadNew = () => {
    onRefresh(); // Calls refreshFeed from the hook
    jumpToTop(600); // Use the new jumpToTop function
    setShowButton(false);
  };

  return (
    <div
      className="new-articles-indicator"
      style={{ top: navbarVisible ? '0px' : '-130px' }} // keep this if needed, or consider moving to scss
    >
      {showButton && (
        <button 
          className={`new-articles-indicator-button ${!isMobile && isScrolled ? 'scrolled' : ''}`}
          onClick={handleLoadNew}
        >
          {isScrolled && <ArrowUpwardIcon className="arrow-icon" />}
          Load latest
        </button>
      )}
    </div>
  );
}