// src/store/store.js

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

// Persist Configuration
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'profile', 'subscriptions', 'ui', 'bookmarks', 'history', 'tag', 'filters'],
};

// Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Determine if Redux DevTools should be enabled
const isDevelopment = process.env.NODE_ENV === 'development';

// Store Configuration
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE'],
      },
      immutableCheck: false, // Immutable check disabled for production
    }),
  devTools: isDevelopment, // Enable DevTools only in development
});

const persistor = persistStore(store);

export { store, persistor };
export default store;
