import React from 'react';
import PropTypes from 'prop-types';
import { MoreVertIcon } from '../../../resources/fonts/icons';
import { getLnk } from '../../../utils/article/article.util';
import './articleDropdownMenu.scss';
import DropDownPortalArticle from '../dropDownPortalBottom/dropDownPortalBottom'; // Updated import

function ArticleDropdownMenu({
  article,
  openShare,
  isBookmarked,
  handleBookMarkClick,
}) {
  const handleSelectionChange = (option) => {
    if (option === 'Share') {
      openShare({
        _id: article._id,
        title: article.title,
        content: article.content_text,
        url: getLnk(article),
      });
    } else {
      handleBookMarkClick();
    }
  };

  return (
    <div className="article-dropdown-menu-container">
      <DropDownPortalArticle
        buttonLabel={<MoreVertIcon className="article-dropdown-menu__icon" />}
        onSelectionChange={handleSelectionChange}
        options={[
          'Share',
          isBookmarked ? 'Remove Bookmark' : 'Bookmark Article',
        ]}
        className="article-dropdown-menu__toggle"
        mobilePosition="bottom" // Ensures dropdown opens at the bottom on mobile
      />
    </div>
  );
}

ArticleDropdownMenu.propTypes = {
  article: PropTypes.object.isRequired,
  openShare: PropTypes.func.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  handleBookMarkClick: PropTypes.func.isRequired,
};

export default ArticleDropdownMenu;
