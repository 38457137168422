import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Snackbar.component.scss';
import ReactDOM from 'react-dom';

const Snackbar = ({ message, isOpen, onClose, duration = 3000 }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [isOpen, duration, onClose]);

  const handleCloseSnackbar = useCallback(() => {
    onClose();
  }, [onClose]);

  const snackbarContent = (
    isOpen && (
      <div className="snackbar">
        <span>{message}</span>
        <button className="snackbar__close" onClick={handleCloseSnackbar} aria-label="Close">
          &times;
        </button>
      </div>
    )
  );

  return isOpen ? ReactDOM.createPortal(snackbarContent, document.body) : null;
};

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
};

export default Snackbar;