// src/resources/fonts/icons.js

// Global Icons
export { default as ArrowForwardIcon } from '@mui/icons-material/ArrowForwardIos';
export { default as ArrowBackIcon } from '@mui/icons-material/ArrowBackIos';
export { default as SearchIcon } from '@mui/icons-material/Search';
export { default as CloseIcon } from '@mui/icons-material/Close';
export { default as ShareIcon } from '@mui/icons-material/Share';
export { default as AddIcon } from '@mui/icons-material/Add';
export { default as RemoveIcon } from '@mui/icons-material/Remove';
export { default as LinkIcon } from '@mui/icons-material/Link';
export { default as MenuIcon } from '@mui/icons-material/Menu';
export { default as ArrowUpwardIcon } from '@mui/icons-material/ArrowUpward';
export { default as GoogleIcon } from '@mui/icons-material/Google';
export { default as DeleteIcon } from '@mui/icons-material/Delete';


// Navigation Icons Outlined
export { default as HomeIconOutlined } from '@mui/icons-material/HomeOutlined';
export { default as TrendingIconOutlined } from '@mui/icons-material/WhatshotOutlined';
export { default as ExploreIconOutlined } from '@mui/icons-material/ExploreOutlined';
export { default as AccountIconOutlined } from '@mui/icons-material/AccountCircleOutlined';
export { default as CollectionsBookmarkIconOutlined } from '@mui/icons-material/CollectionsBookmarkOutlined';

// Navigation Icons Filled
export { default as HomeIconFilled } from '@mui/icons-material/Home';
export { default as TrendingIconFilled } from '@mui/icons-material/Whatshot';
export { default as ExploreIconFilled } from '@mui/icons-material/Explore';
export { default as AccountIconFilled } from '@mui/icons-material/AccountCircle';
export { default as CollectionsBookmarkIconFilled } from '@mui/icons-material/CollectionsBookmark';


// Article Icons Filled
export { default as ThumbUpIcon } from '@mui/icons-material/ThumbUp';
export { default as ThumbDownIcon } from '@mui/icons-material/ThumbDown';
export { default as BookmarkIconFilled } from '@mui/icons-material/Bookmark';
export { default as ShareIconFilled } from '@mui/icons-material/IosShare';
export { default as ExpandMoreIcon } from '@mui/icons-material/ExpandMore';
export { default as ExpandLessIcon } from '@mui/icons-material/ExpandLess';


// Article Icons Outlined
export { default as ThumbUpIconOutlined } from '@mui/icons-material/ThumbUpOutlined';
export { default as ThumbDownIconOutlined } from '@mui/icons-material/ThumbDownOutlined';
export { default as ShareIconOutlined } from '@mui/icons-material/IosShareOutlined';
export { default as BookmarkIconOutlined } from '@mui/icons-material/BookmarkBorder';
export { default as MoreVertIcon } from '@mui/icons-material/MoreVert';
export { default as MoreIcon } from '@mui/icons-material/MoreHoriz';
export { default as PlayArrowIcon } from '@mui/icons-material/PlayArrow';
export { default as ModeCommentOutlinedIcon } from '@mui/icons-material/ModeCommentOutlined';


// Additional Icons
export { default as VisibilityIcon } from '@mui/icons-material/Visibility';
export { default as VisibilityOffIcon } from '@mui/icons-material/VisibilityOff';
export { default as CheckCircleOutlineIcon } from '@mui/icons-material/CheckCircleOutline';
export { default as TagIcon } from '@mui/icons-material/Tag';


// Custom Discord Icons
export const DiscordIconFilled = () => (
  <img
    src="https://storage.googleapis.com/tattle-public-images/site/discordicon-filled.svg"
    alt="Discord Filled Icon"
    style={{ width: '24px', height: '24px' }} // Adjust size as needed
  />
);

export const DiscordIconOutlined = () => (
  <img
    src="https://storage.googleapis.com/tattle-public-images/site/discordicon-outline.svg"
    alt="Discord Outlined Icon"
    style={{ width: '24px', height: '24px' }} // Adjust size as needed
  />
);

// Custom Tattle Logo Icon
export const TattleLogoIcon = ({ className }) => (
  <img
    src="https://storage.googleapis.com/tattle-public-images/site/tattlelogoicon.svg"
    alt="Tattle Logo Icon"
    className={className} // Updated to use passed className
  />
);

// Add new Tattle Beta Type logo icon
export const TattleBetaType = ({ className }) => (
  <img
    src="https://storage.googleapis.com/tattle-public-images/site/TattlebetaType.svg"
    alt="Tattle Beta Type Logo"
    className={className} // Use passed className for styling if needed
  />
);