import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './dropDownPortalTop.scss';

export default function DropDownPortalTimeFrame({ onSelectionChange, options, buttonLabel, className, mobilePosition = 'top' }) { // Changed default from 'default' to 'top'
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);
  const containerRef = useRef(null); // Added container ref
  const menuRef = useRef(null); // Added menu ref
  const isScrollingRef = useRef(false); // Added scrolling ref

  const handleSelection = (option) => {
    onSelectionChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false); // Close the menu when scrolling
      isScrollingRef.current = true;
      clearTimeout(isScrollingRef.current);
      isScrollingRef.current = setTimeout(() => {
        isScrollingRef.current = false;
      }, 100); // Reset after 100ms
    };

    if (isOpen) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('touchmove', handleScroll, { passive: true });
    } else {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isScrollingRef.current) return; // Prevent closing if scrolling
      if (
        containerRef.current && !containerRef.current.contains(event.target) &&
        menuRef.current && !menuRef.current.contains(event.target) // Check menuRef
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside); // Changed from 'mousedown' to 'click'
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    const nextState = !isOpen;
    setIsOpen(nextState);
    if (nextState && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom, // Removed window.scrollY
        left: rect.left    // Removed window.scrollX
      });
    }
  };

  return (
    <div ref={containerRef} className={`timeframe-dropdown-modal-container ${isOpen ? 'open' : ''}`}> {/* Added ref */}
      <button
        ref={buttonRef}
        className={className}
        onClick={toggleDropdown}
      >
        {buttonLabel}
      </button>
      {isOpen && ReactDOM.createPortal(
        <>
          {window.innerWidth <= 600 && ( // Added condition for mobile
            <div
              className="dropdown-mask" // Render mask
              onClick={() => setIsOpen(false)} // Close dropdown on mask click
            />
          )}
          <ul
            ref={menuRef} // Assigned ref to menu
            className={`timeframe-dropdown-menu ${
              window.innerWidth <= 600 && mobilePosition === 'bottom' ? 'bottom' : ''
            }`}
            style={{
              '--top': `${menuPosition.top}px`,
              '--left': `${menuPosition.left}px`
            }}
          >
            {options.map(option => (
              <li key={option} onClick={() => handleSelection(option)}>
                {option}
              </li>
            ))}
          </ul>
        </>,
        document.body
      )}
    </div>
  );
}