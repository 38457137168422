// src/containers/authentication/authentication.container.js

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  loginUser,
  resetAuthState,
  registerUser,
  hideLoginForm,
} from '../../reducers/authSlice';
import { AuthenticationComponent } from '../../components';
import Snackbar from '../../components/snackbar/Snackbar.component'; // Import the Snackbar component
import PropTypes from 'prop-types';
import './authentication.container.css';

const AuthenticationContainer = ({
  loginFormVisible,
  onClose,
  formType,
  redirect: initialRedirect,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const redirect = initialRedirect || window.location.pathname;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false); // Ensure this state is defined

  useEffect(() => {
    // Removed empty blocks
  }, [loginFormVisible]);

  useEffect(() => {
    if (loginFormVisible && authState.isAuthenticated) {
      navigate(redirect);
      onClose();
    }
  }, [loginFormVisible, authState.isAuthenticated, redirect, navigate, onClose]);

  useEffect(() => {
    if (authState.error) {
      setErrorMessage(authState.error); // Directly use the error message
      setIsSnackbarOpen(true);
    }
  }, [authState.error]);

  const clearErrorStatus = useCallback(() => {
    dispatch(resetAuthState());
    setErrorMessage(''); // Clear error message
  }, [dispatch]);

  const handleLoginClick = useCallback((form) => {
    if (form.isValid) {
      dispatch(loginUser(form.values))
        .unwrap()
        .then(() => {
          dispatch(hideLoginForm());
          navigate(redirect);
        })
        .catch(() => { 
          setIsSnackbarOpen(true); // Open Snackbar on error
          // Ensure the modal remains open by not calling hideLoginForm()
        });
    }
  }, [dispatch, navigate, redirect]);

  const handleRegisterClick = useCallback((form) => {
    if (form.isValid) {
      dispatch(registerUser(form.values))
        .unwrap()
        .then(() => {
          navigate(redirect);
          dispatch(hideLoginForm());
        })
        .catch(() => { 
          setIsSnackbarOpen(true); // Open Snackbar on error
          // Do not close the modal on error
        });
    }
  }, [dispatch, navigate, redirect]);

  const handleForgotPasswordClick = useCallback(() => {
    // Implement if necessary
  }, []);

  const handleClose = useCallback(() => {
    dispatch(hideLoginForm());
    onClose();
    setErrorMessage(''); // Clear error message on close
  }, [dispatch, onClose]);

  const handleCloseSnackbar = useCallback(() => {
    setIsSnackbarOpen(false);
    setErrorMessage('');
    clearErrorStatus(); // Keep only one call to clearErrorStatus
  }, [clearErrorStatus]);

  const handleContainerClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    loginFormVisible ? (
      <div className="login-form" onClick={handleContainerClick}>
        <Fragment>
          <div className={`authentication-container ${loginFormVisible ? 'visible' : ''}`}>
            <AuthenticationComponent
              isVisible={loginFormVisible}
              onHide={handleClose}
              onLoginClick={handleLoginClick}
              onRegisterClick={handleRegisterClick}
              onForgotPasswordClick={handleForgotPasswordClick}
              onClearErrorStatus={clearErrorStatus}
              profile={authState}
              formType={formType}
              errorMessage={errorMessage} // Pass errorMessage prop
            />
          </div>
          <Snackbar
            message={errorMessage}
            isOpen={isSnackbarOpen}
            onClose={handleCloseSnackbar}
            duration={5000}
          />
        </Fragment>
      </div>
    ) : null
  );
};

AuthenticationContainer.propTypes = {
  loginFormVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AuthenticationContainer;