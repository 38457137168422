import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getClassName } from '../../../utils';
import { validateField } from '../../../utils/form/validation.util';
import { VisibilityIcon, VisibilityOffIcon } from '../../../resources/fonts/icons';
import './formField.component.scss';

const FormField = ({
  initialValue = '',
  type = 'text',
  name,
  id,
  minLength = 3,
  maxLength = 254,
  disabled,
  autoComplete = 'off',
  onChange,
  onKeyDown,
  matchValue,
  required = false,
  label,
  size,
  className,
  theme,
  normalize,
  parse,
  validateOnBlur = true,
  validateOnChange = true,
  touched = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isTouched, setIsTouched] = useState(touched);
  const [hasModified, setHasModified] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword(prev => !prev);
  }, []);

  useEffect(() => {
    if (initialValue) {
      const validation = validateField(name, initialValue, matchValue, required);
      setError(!validation.isValid ? validation.message : null);
      setIsValid(validation.isValid);
      if (onChange) {
        onChange({ target: { name, value: initialValue } }, initialValue, validation);
      }
    }
  }, [initialValue, name, matchValue, required, onChange]);

  const validateInput = useCallback(
    (val) => {
      let validation = validateField(name, val, matchValue, required, label);
      if (required && val.trim() === '' && !hasModified) {
        validation = { isValid: true, message: '' };
      }
      setError(!validation.isValid ? validation.message : null);
      setIsValid(validation.isValid);
      return validation;
    },
    [name, matchValue, required, label, hasModified]
  );

  const parseValue = useCallback((val) => {
    if (parse && typeof parse === 'function') {
      return parse(val);
    }
    return val;
  }, [parse]);

  const normalizeValue = useCallback((val) => {
    if (normalize && typeof normalize === 'function') {
      return normalize(val);
    }
    return val;
  }, [normalize]);

  const handleInputChange = (event) => {
    const rawValue = event.target.value;
    if (!hasModified && rawValue !== initialValue) {
      setHasModified(true);
    }
    const parsedValue = parseValue(rawValue);
    const normalizedValue = normalizeValue(parsedValue);
    
    setValue(normalizedValue);

    if (validateOnChange) {
      const validation = validateInput(normalizedValue);
      if (onChange) {
        onChange(event, normalizedValue, validation);
      }
    }
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    setIsTouched(true);

    if (validateOnBlur) {
      const validation = validateInput(value);
      if (onChange) {
        onChange(event, value, validation);
      }
    }
  };

  const handleFocus = () => setIsFocused(true);

  const renderInput = () => {
    const inputClass = `field_input ${error ? 'field_input__error' : ''}`;

    const inputProps = {
      className: inputClass,
      name,
      id,
      value,
      disabled,
      onChange: handleInputChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onKeyDown,
      autoComplete,
      minLength,
      maxLength,
      // Ensure that pressing Enter does not submit the form unexpectedly
      onKeyPress: (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      },
    };

    if (type === 'textarea') {
      return <textarea {...inputProps} />;
    }

    const inputType = type === 'password' && showPassword ? 'text' : type;

    return (
      <>
        <input
          {...inputProps}
          type={inputType}
        />
        {type === 'password' && (
          <span
            onClick={toggleShowPassword}
            className="password-visibility"
            role="button"
            tabIndex={-1} // Changed from 0 to -1
          >
            <div className="password-visibility-container">
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </div>
          </span>
        )}
      </>
    );
  };

  const componentClass = getClassName('field', [
    { condition: className, trueClassName: className },
    { condition: type, trueClassName: `field--${type}` },
    { condition: theme, trueClassName: `field--${theme}` },
    { condition: size, trueClassName: `field--${size}` },
    { condition: disabled, trueClassName: `field--disabled` },
    { condition: !value, trueClassName: 'field--empty' },
  ]);

  const shouldFloatLabel = isFocused || value;

  return (
    <div className={componentClass}>
      <div className="field-input-wrapper">
        {renderInput()}
        {label && (
          <label
            className={`floating-label ${shouldFloatLabel ? 'floating' : ''} ${shouldFloatLabel && isValid && value ? 'valid' : ''} ${shouldFloatLabel && !isValid ? 'error' : ''}`}
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </div>
      {error && isTouched && <div className="field_error">{error}</div>}
    </div>
  );
};

FormField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  size: PropTypes.oneOf(['full', 'half', 'third', 'short', 'shorter', 'shortest']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  matchValue: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  touched: PropTypes.bool,
};

FormField.defaultProps = {
  type: 'text',
  initialValue: '',
  minLength: 3,
  maxLength: 64,
};

export default React.memo(FormField);
