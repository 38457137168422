import { useDispatch, useSelector } from 'react-redux'; // Added useSelector
import { createBookmark, deleteBookmark, selectBookmarksByArticleId } from '../reducers/bookmarksSlice'; // Added selectBookmarksByArticleId
import { authEventEmitter } from '../containers/navigation/navigation.container';

const useBookmark = () => {
  const dispatch = useDispatch();
  const bookmarkMap = useSelector(selectBookmarksByArticleId); // Added bookmarkMap
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const profileData = useSelector(state => state.profile.profileData);
  const error = useSelector(state => state.bookmarks.error); // Added error state

  /**
   * Toggle the bookmark state for a given article.
   * @param {Object} article - The article to toggle bookmark for.
   * @param {String} bookmarkId - The ID of the bookmark if it exists.
   * @param {Boolean} isBookmarked - Whether the article is currently bookmarked.
   */
  const toggleBookmark = async (article, bookmarkId, isBookmarked) => {
    if (!isAuthenticated) {
      authEventEmitter.emit('triggerAuth');
      return;
    }

    try {
      if (isBookmarked) {
        console.log(`Deleting bookmark with ID: ${bookmarkId}`);
        await dispatch(deleteBookmark(bookmarkId)).unwrap();
        console.log('Bookmark deleted successfully.');
        // Optionally, refetch bookmarks
        // dispatch(fetchBookmarks());
      } else {
        const body = {
          profileId: profileData?._id,
          articleId: article._id,
          topicId: article._topic?._id,
        };
        console.log('Creating bookmark with data:', body);
        await dispatch(createBookmark(body)).unwrap();
        console.log('Bookmark created successfully.');
        // Optionally, refetch bookmarks
        // dispatch(fetchBookmarks());
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
      // Optionally, handle errors (e.g., show a notification).
    }
  };

  return { toggleBookmark, bookmarkMap, error }; // Return error
};

export default useBookmark;
