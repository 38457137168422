import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setCategory } from './filterSlice'; // Import setCategory

// Create thunk to handle both tag and filter updates
export const updateActiveTag = createAsyncThunk(
  'tag/updateActiveTag',
  async (tag, { dispatch, getState }) => {
    const state = getState();
    const currentCategory = state.filters.category; // Get the current category

    dispatch(setActiveTag(tag));
    dispatch(setCategory(currentCategory)); // Preserve the current category
    return tag;
  }
);

const initialState = {
  activeTag: 'All',
  dropDownFilterMenu: 'Day', // RENAMED from timeFrame to dropDownFilterMenu
  tags: ['All', 'Articles', 'Videos'], // Initialize tags list
};

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setActiveTag: (state, action) => {
      state.activeTag = action.payload;
    },
    setDropDownFilterMenu: (state, action) => { // UPDATED reducer name
      state.dropDownFilterMenu = action.payload;
    },
    addTag: (state, action) => {
      if (!state.tags.includes(action.payload)) {
        state.tags.push(action.payload);
      }
    },
    removeTag: (state, action) => {
      state.tags = state.tags.filter(tag => tag !== action.payload);
    },
    resetActiveTag: (state) => {
      state.activeTag = 'All';
    }
  },
});

export const { setActiveTag, setDropDownFilterMenu, addTag, removeTag, resetActiveTag } = tagSlice.actions; // UPDATED export

export default tagSlice.reducer;