// src/components/Button.jsx
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TattleLink from '../../components/link/link.component';
import styles from './button.module.scss'; // Changed from .css to .scss

const Button = forwardRef(
  (
    {
      className,
      variant = 'regular', // Default to 'regular' variant if none is specified
      linkTo,
      linkTarget,
      style = {},
      label,
      icon,
      iconAfter,
      image,
      disabled = false,
      onClick,
      onMouseEnter = () => {},
      onMouseLeave = () => {},
      children,
      ...rest
    },
    ref
  ) => {
    const handleClick = (event) => {
      event.target.blur();
      if (onClick) onClick(event);
    };

    const btnClassNames = classNames(
      styles.btn,
      className,
      styles[`btn--${variant}`], // supports 'regular', 'icon', 'blue'
      {
        [styles['btn--disabled']]: disabled,
        [styles['btn--active']]: rest.active,
      }
    );

    // Variant prop mapping: [styles[`btn--${variant}`]] applies btn--blue when variant === "blue"

    const content = (
      <> 
        {icon && (
          <span style={style.icon}>
            {icon}
          </span>
        )}
        {image && <img src={image} alt="" className={styles.btn__image} />}
        {label && (
          <span className={styles.btn__label} style={style.label}>
            {label}
          </span>
        )}
        {iconAfter && (
          <span style={style.icon} className={styles.btn__icon_after}>
            {iconAfter}
          </span>
        )}
        {children && (
          <span className={styles.btn__text}>
            {children}
          </span>
        )}
      </>
    );

    const commonProps = {
      className: btnClassNames,
      style: style.base,
      onClick: handleClick,
      onMouseEnter,
      onMouseLeave,
      ref,
      disabled,
      ...rest,
    };

    return linkTo ? (
      <TattleLink {...commonProps} to={linkTo} target={linkTarget}>
        {content}
      </TattleLink>
    ) : (
      <button {...commonProps}>{content}</button>
    );
  }
);

Button.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string,
  linkTarget: PropTypes.string,
  style: PropTypes.shape({
    base: PropTypes.object,
    icon: PropTypes.object,
    label: PropTypes.object,
  }),
  variant: PropTypes.string, // Add prop type for 'variant'
  icon: PropTypes.node,
  iconAfter: PropTypes.node,
  image: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
};

export default Button;
