// src/reducers/feedbackSlice.js

import { createSlice } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import { createThunkWithErrorHandler, handleAsync } from '../utils/reduxUtils/reduxUtils';

/**
 * Thunk to send feedback
 */
export const sendFeedback = createThunkWithErrorHandler(
  'feedback/sendFeedback',
  async ({ feedback, timestamp, userEmail, userAgent }) => {
    const response = await api.post('/private/feedback', {
      feedback,
      timestamp,
      userEmail,
      userAgent,
    });
    return response.data;
  }
);

// Feedback slice
const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {
    resetFeedbackState: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    handleAsync(builder, sendFeedback);
  },
});

export const { resetFeedbackState } = feedbackSlice.actions;
export default feedbackSlice.reducer;
