// src/services/auth.service.js

import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import api from '../api/axiosInstance'; 
import config from '../config';
import { handleError } from '../utils/errors/errorHandler'; 

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const REFRESH_THRESHOLD_IN_SECONDS = 10 * 60; // 10 minutes

const { API_URL, HEADERS: defaultHeaders } = config;

/**
 * AuthService handles all authentication-related operations,
 * including token management and user session handling.
 */
class AuthService {
  static isRefreshing = false;
  static refreshPromise = null;
  static isLoggedOut = false;

  static decodeToken(token) {
    try {
      return token ? jwtDecode(token) : null;
    } catch (error) {
      this.handleLogout();
      return null;
    }
  }

  /**
   * Checks if the user is currently authenticated.
   * @returns {boolean} - True if authenticated, else false.
   */
  static isAuthenticated() {
    const token = this.getLocalStorage(ACCESS_TOKEN);
    return this.tokenIsValid(token);
  }

  /**
   * Retrieves the access token, refreshing it if it's near expiry.
   * @returns {Promise<string|null>} - The valid access token or null if unavailable.
   */
  static async getAccessToken() {
    if (this.isLoggedOut) return null;

    const token = this.getLocalStorage(ACCESS_TOKEN);
    if (!token) {
      return null;
    }

    if (this.isTokenNearExpiry(token)) {
      try {
        await this.refreshToken();
        return this.getLocalStorage(ACCESS_TOKEN);
      } catch (error) {
        handleError(error, 'AuthService.getAccessToken');
        return null;
      }
    }
    return token;
  }

  /**
   * Checks if a token is near expiry.
   * @param {string} token - JWT access token.
   * @returns {boolean} - True if token is near expiry, else false.
   */
  static isTokenNearExpiry(token) {
    const decoded = this.decodeToken(token);
    if (!decoded) return false;
    const timeLeft = decoded.exp - (Date.now() / 1000);
    return timeLeft <= REFRESH_THRESHOLD_IN_SECONDS;
  }

  /**
   * Extracts the user ID from the access token.
   * @returns {string|null} - The user ID or null if not available.
   */
  static getUserId() {
    const decoded = this.decodeToken(this.getLocalStorage(ACCESS_TOKEN));
    return decoded ? (decoded.user_id || decoded.sub || decoded.id || decoded._id) : null;
  }

  /**
   * Refreshes the access token using the refresh token.
   * @returns {Promise<string>} - The new access token.
   */
  static async refreshToken() {
    console.log('AuthService.refreshToken: refreshing token');
    if (this.isRefreshing) {
      console.log('AuthService.refreshToken: already refreshing');
      return this.refreshPromise;
    }

    const refreshToken = this.getLocalStorage(REFRESH_TOKEN);
    if (!refreshToken) {
      console.log('AuthService.refreshToken: no refresh token available');
      this.handleLogout();
      return Promise.reject(new Error('No refresh token available')); // Modify this line
    }

    this.isRefreshing = true;
    this.refreshPromise = axios
      .post(
        `${API_URL}/auth/refreshToken`,
        { refresh_token: refreshToken },
        { headers: defaultHeaders }
      )
      .then((response) => {
        const data = response.data;
        const newAccessToken = data.access_token;
        const newRefreshToken = data.refresh_token;

        if (newAccessToken && newRefreshToken) {
          this.setSession({ access_token: newAccessToken, refresh_token: newRefreshToken });
          console.log('AuthService.refreshToken: token refreshed', { newAccessToken, newRefreshToken });
          return newAccessToken;
        } else {
          throw new Error('Invalid token data received from refresh endpoint');
        }
      })
      .catch((error) => {
        console.error('AuthService.refreshToken: token refresh failed', error);
        this.handleLogout();
        const errorMessage = handleError(error, 'AuthService.refreshToken'); // Use handleError
        throw new Error(errorMessage); // Modify this line
      })
      .finally(() => {
        this.isRefreshing = false;
        this.refreshPromise = null;
      });

    return this.refreshPromise;
  }

  /**
   * Handles user logout by clearing tokens and purging the store.
   */
  static handleLogout() {
    this.clearLocalStorage();
    this.isLoggedOut = true;
  }

  /**
   * Handles user login by storing access and refresh tokens.
   * @param {Object} tokenData - The token data received from the authentication server.
   */
  static handleLogin(tokenData) {
    console.log('AuthService.handleLogin: tokenData', tokenData);
    let accessToken, refreshToken;

    if (tokenData.access_token && tokenData.refresh_token) {
      accessToken = tokenData.access_token;
      refreshToken = tokenData.refresh_token;
    } else if (
      tokenData.token &&
      tokenData.token.access_token &&
      tokenData.token.refresh_token
    ) {
      accessToken = tokenData.token.access_token;
      refreshToken = tokenData.token.refresh_token;
    } else {
      console.log('AuthService.handleLogin: invalid tokenData', tokenData);
      return;
    }

    this.setSession({ access_token: accessToken, refresh_token: refreshToken });
    this.isLoggedOut = false; // Ensure the user is marked as logged in
    console.log('AuthService.handleLogin: session set', { accessToken, refreshToken });
  }

  /**
   * Sets the session tokens in localStorage.
   * @param {Object} authResult - The authentication result containing tokens.
   */
  static setSession(authResult) {
    if (authResult.access_token && authResult.refresh_token) {
      this.setLocalStorage(ACCESS_TOKEN, authResult.access_token);
      this.setLocalStorage(REFRESH_TOKEN, authResult.refresh_token);
    } else {
      this.handleLogout();
    }
  }

  /**
   * Validates the provided JWT token.
   * @param {string} token - JWT token to validate.
   * @returns {boolean} - True if token is valid, else false.
   */
  static tokenIsValid(token) {
    const decoded = this.decodeToken(token);
    if (!decoded) return false;
    return Date.now() / 1000 < decoded.exp;
  }

  /**
   * Retrieves authentication headers with a valid access token.
   * @returns {Promise<Object>} - The headers object containing the Authorization header.
   */
  static async getAuthHeaders() {
    const token = await this.getAccessToken();
    const authHeaders = {
      ...defaultHeaders,
      ...(token && { Authorization: `Bearer ${token}` }),
    };
    return authHeaders;
  }

  /**
   * Utility function to set a value in localStorage.
   * @param {string} key - The key under which the value is stored.
   * @param {string} value - The value to store.
   */
  static setLocalStorage(key, value) {
    try {
      if (value !== undefined && value !== null) {
        localStorage.setItem(key, value);
      }
    } catch (error) {
      // Optionally, notify the user about the storage failure
    }
  }

  /**
   * Utility function to get a value from localStorage.
   * @param {string} key - The key of the value to retrieve.
   * @returns {string|null} - The retrieved value or null if not found.
   */
  static getLocalStorage(key) {
    try {
      return localStorage.getItem(key) || null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Utility function to clear tokens from localStorage.
   */
  static clearLocalStorage() {
    [ACCESS_TOKEN, REFRESH_TOKEN].forEach((key) => localStorage.removeItem(key));
  }

  /**
   * Login method to handle user authentication.
   * @param {Object} credentials - User credentials for login.
   * @returns {Promise<Object>} - The token data.
   */
  static async login(credentials) {
    return this.handleAuthRequest('/auth/login', credentials);
  }

  /**
   * Generic method to handle authentication requests
   * @param {string} endpoint - The authentication endpoint
   * @param {Object} data - The data to send
   * @returns {Promise<Object>} - The token data
   */
  static async handleAuthRequest(endpoint, data) {
    try {
      const response = await api.post(endpoint, data);
      const tokenData = response.data.data || response.data;
      this.handleLogin(tokenData);
      return tokenData;
    } catch (error) {
      const errorMessage = handleError(error, `AuthService.handleAuthRequest:${endpoint}`); // Modify this line
      throw new Error(errorMessage); // Modify this line
    }
  }

  /**
   * Logout the user by clearing tokens and purging the store.
   */
  static async logout() {
    try {
      this.handleLogout();
    } catch (error) {
      const errorMessage = handleError(error, 'AuthService.logout'); // Use handleError
      throw new Error(errorMessage); // Modify this line
    }
  }
}

export default AuthService;
