import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/button.component';
import { ArrowBackIcon } from '../../../../resources/fonts/icons';
import './settingsHeader.css';

const SettingsHeader = ({ title, showBackButton = true }) => {
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="settings-header-container">
      {showBackButton && (
        <Button
          className="settings-header-icon"
          theme="transparent"
          icon={<ArrowBackIcon />}
          onClick={onBackClick}
        />
      )}
      <span className="settings-header-title">{title}</span>
    </div>
  );
};

export default SettingsHeader;