import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AuthenticationContainer from '../../../containers/authentication/authentication.container';
import { authEventEmitter } from '../../../containers/navigation/navigation.container';

const SignInHandler = ({ onRenderSignInButton, SignInIcon }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const [loginFormVisible, setLoginFormVisible] = useState(false);
  const [formType, setFormType] = useState('login');

  // Set login form based on query parameters
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setLoginFormVisible(query.get('login') !== null);
  }, [location.search]);

  // Hide form when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      setLoginFormVisible(false);
    }
  }, [isAuthenticated]);

  const toggleLoginForm = useCallback((type = 'login') => {
    setLoginFormVisible((prev) => !prev);
    setFormType(type);
  }, []);

  useEffect(() => {
    const handleAuthTrigger = () => {
      toggleLoginForm('login');
    };
    authEventEmitter.on('triggerAuth', handleAuthTrigger);
    return () => {
      authEventEmitter.off('triggerAuth', handleAuthTrigger);
    };
  }, [toggleLoginForm]);

  return (
    <>
      {onRenderSignInButton && !isAuthenticated && (
        <div className="nav-item-container">
          <div className="nav-item" onClick={() => toggleLoginForm('login')}>
            <div className="nav-item-icon-container">
              <div className="nav-item-icon">
                {SignInIcon}
              </div>
            </div>
            <div className="nav-item-text">
              Sign In
            </div>
          </div>
        </div>
      )}
      {loginFormVisible && (
        <AuthenticationContainer
          loginFormVisible={loginFormVisible}
          onClose={toggleLoginForm}
          formType={formType}
        />
      )}
    </>
  );
};

export default SignInHandler;