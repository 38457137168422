// src/reducers/topicSlice.js

import { createSlice } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import queryString from 'query-string';
import {
  createThunkWithErrorHandler,
  handleAsync,
} from '../utils/reduxUtils/reduxUtils';
import { handleError } from '../utils/errors/errorHandler'; // Add this import

// Fetch Topic by ID or Value
export const fetchTopicById = createThunkWithErrorHandler(
  'topic/fetchTopicById',
  async ({ value, params }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const isAuthenticated = state.auth.isAuthenticated;
      const profileId = state.profile.profileData?._id;

      const queryParams = { type: 'seo_name', ...params };
      let url = isAuthenticated ? '/private/topic/' : '/public/topic/';
      url += value;

      if (isAuthenticated && profileId) {
        queryParams.profile = profileId;
      }

      const query = queryString.stringify(queryParams);
      if (query) {
        url += `?${query}`;
      }

      const response = await api.get(url);

      const data = response.data.data || response.data.topic || response.data;

      return data;
    } catch (error) {
      const errorMessage = handleError(error, 'fetchTopicById');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Fetch Topics by Category Name */
export const fetchTopicsByCategory = createThunkWithErrorHandler(
  'topic/fetchTopicsByCategory',
  async (category, { rejectWithValue }) => {
    try {
      const response = await api.get(`/public/topic/category/${category}`);
      return response.data.data;
    } catch (error) {
      const errorMessage = handleError(error, 'fetchTopicsByCategory');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Get Subscription Count */
export const getTopicSubscriptionCount = createThunkWithErrorHandler(
  'topic/getTopicSubscriptionCount',
  async (topicId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/public/topic/subscriptions/${topicId}`);
      return response.data.data;
    } catch (error) {
      const errorMessage = handleError(error, 'getTopicSubscriptionCount');
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial state
const initialState = {
  topicDetails: null,
  topicsList: [],
  subscriptionCount: 0,
  status: 'idle',
  error: null,
};

// Topic slice
const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    resetTopicState: () => initialState,
  },
  extraReducers: (builder) => {
    // Fetch Topic by ID
    handleAsync(builder, fetchTopicById, (state, action) => {
      state.topicDetails = action.payload;
    });

    // Fetch Topics by Category Name
    builder
      .addCase(fetchTopicsByCategory.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTopicsByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topicsList = action.payload;
        state.error = null;
      })
      .addCase(fetchTopicsByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = handleError(action.error, 'fetchTopicsByCategory');
      });

    // Get Subscription Count
    handleAsync(builder, getTopicSubscriptionCount, (state, action) => {
      state.subscriptionCount = action.payload.count || 0;
    });
  },
});

// Export actions and reducer
export const { resetTopicState } = topicSlice.actions;
export default topicSlice.reducer;
