import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const getAbbreviatedTimeDistance = (date) => {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  if (seconds < 60) return `${seconds}s ago`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}min ago`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}hr${hours > 1 ? 's' : ''} ago`;
  const days = Math.floor(hours / 24);
  if (days < 31) return `${days}d ago`;
  const months = Math.floor(days / 31);
  if (months < 12) return `${months}mo ago`;
  const years = Math.floor(months / 12);
  return `${years}yr${years > 1 ? 's' : ''} ago`;
};

const Timestamp = ({ publishDate, className }) => {
  const dateObj = new Date(publishDate);
  const [timeText, setTimeText] = useState(getAbbreviatedTimeDistance(dateObj));

  useEffect(() => {
    const oneDay = 86400000;
    if (Date.now() - dateObj.getTime() < oneDay) {
      const intervalId = setInterval(() => {
        setTimeText(getAbbreviatedTimeDistance(dateObj));
      }, 300000); // update every 5 minutes
      return () => clearInterval(intervalId);
    }
  }, [publishDate, dateObj]);

  return <span className={className}>{timeText}</span>;
};

Timestamp.propTypes = {
  publishDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  className: PropTypes.string,
};

Timestamp.defaultProps = {
  className: '',
};

export default Timestamp;
