// src/reducers/historySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import { handleError } from '../utils/errors/errorHandler';

// Async thunk to fetch history with full article details
export const fetchHistory = createAsyncThunk(
  'history/fetchHistory',
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/private/history?page=${page}&limit=${limit}`);
      const historyItems = response.data.data || [];

      return historyItems.map(item => ({
        ...item,
        uniqueKey: `${item._id}-${item.timestamp}`, // Add a unique key if not present
      }));
    } catch (error) {
      const errorMessage = handleError(error, 'fetchHistory');
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk to add an article to history without fetching article details
export const addHistory = createAsyncThunk(
  'history/addHistory',
  async (articleId, { rejectWithValue }) => { // Removed dispatch from arguments
    try {
      // Add the article to history
      const response = await api.post('/private/history', { articleId });
      const historyItem = response.data.data;

      // Return the history item directly without fetching article details
      return historyItem;
    } catch (error) {
      const errorMessage = handleError(error, 'addHistory');
      return rejectWithValue(errorMessage);
    }
  }
);

export const loadClickedArticles = createAsyncThunk(
  'history/loadClickedArticles',
  async () => {
    const storedClickedIds = JSON.parse(localStorage.getItem('clickedArticleIds')) || [];
    return storedClickedIds;
  }
);

const initialState = {
  items: [],
  filteredItems: [], // Ensure this is initialized
  clickedArticleIds: [],
  status: 'idle',
  error: null,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    clearHistory: (state) => {
      state.items = [];
      state.clickedArticleIds = [];
      state.status = 'idle';
      state.error = null;
    },
    markArticleAsClicked: (state, action) => {
      if (!state.clickedArticleIds.includes(action.payload)) {
        state.clickedArticleIds.push(action.payload);
        localStorage.setItem('clickedArticleIds', JSON.stringify(state.clickedArticleIds));
      }
    },
    setFilteredHistory: (state, action) => {
      state.filteredItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const uniqueItems = [];
        const ids = new Set();
        action.payload.forEach(item => {
          if (!ids.has(item._id)) {
            ids.add(item._id);
            uniqueItems.push(item);
          }
        });
        state.items = uniqueItems;
      })
      .addCase(fetchHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addHistory.fulfilled, (state, action) => {
        const exists = state.items.some(item => item._id === action.payload._id);
        if (!exists) {
          state.items.unshift(action.payload);
        }
      })
      .addCase(addHistory.rejected, () => {
        // Optionally handle the error state
      })
      .addCase(loadClickedArticles.fulfilled, (state, action) => {
        state.clickedArticleIds = action.payload;
      });
  },
});

export const { clearHistory, markArticleAsClicked, setFilteredHistory } = historySlice.actions;

// Selectors
export const selectFilteredHistory = (state) => {
  const filtered = state.history?.filteredItems;
  const allHistory = state.history?.items;

  if (Array.isArray(filtered) && filtered.length > 0) {
    return filtered;
  }

  return Array.isArray(allHistory) ? allHistory : [];
};

// Search action
export const searchHistory = (searchTerm) => (dispatch, getState) => {
  const { items } = getState().history;
  if (!Array.isArray(items)) return;

  const filtered = items.filter((historyItem) =>
    historyItem.article?.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  dispatch(setFilteredHistory(filtered));
};

export default historySlice.reducer;