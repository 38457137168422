
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
};

const apiMessageSlice = createSlice({
  name: 'apiMessage',
  initialState,
  reducers: {
    setApiMessage: (state, action) => {
      state.message = action.payload;
    },
    clearApiMessage: (state) => {
      state.message = null;
    },
  },
});

export const { setApiMessage, clearApiMessage } = apiMessageSlice.actions;
export default apiMessageSlice.reducer;