// src/reducers/policySlice.js

import { createSlice } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import { createThunkWithErrorHandler, handleAsync } from '../utils/reduxUtils/reduxUtils';

/**
 * Async Thunk to fetch policy by type
 */
export const fetchPolicyByType = createThunkWithErrorHandler(
  'policies/fetchPolicyByType',
  async (type) => {
    const response = await api.get(`/public/policy/${type}`);
    return response.data.data;
  }
);

// Policy slice
const policySlice = createSlice({
  name: 'policies',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    resetPolicy: (state) => {
      state.data = {};
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    handleAsync(builder, fetchPolicyByType, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { resetPolicy } = policySlice.actions;
export default policySlice.reducer;
