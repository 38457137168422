// src/reducers/index.js

import { combineReducers } from '@reduxjs/toolkit';
import articlesReducer from './articleSlice';
import authReducer, { logoutUser } from './authSlice';
import profileReducer from './profileSlice';
import topicReducer from './topicSlice';
import searchReducer from './searchSlice';
import policyReducer from './policySlice';
import feedbackReducer from './feedbackSlice';
import subscriptionsReducer from './subscriptionsSlice';
import bookmarksReducer from './bookmarksSlice';
import categoriesReducer from './categorySlice';
import historyReducer from './historySlice';
import tagReducer from './tagSlice';
import apiMessages from './apiMessageSlice';
import filterReducer from './filterSlice';

// Combine all reducers
const appReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  topic: topicReducer,   
  search: searchReducer,
  policies: policyReducer,
  feedback: feedbackReducer,
  subscriptions: subscriptionsReducer,
  bookmarks: bookmarksReducer,
  categories: categoriesReducer,
  articles: articlesReducer,
  history: historyReducer,
  tag: tagReducer,
  apiMessages: apiMessages,
  filters: filterReducer,
});

// Root reducer that resets state on logout
const rootReducer = (state, action) => {
  if (action.type === logoutUser.fulfilled.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
