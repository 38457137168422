import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { getClassName } from '../../utils/ui/ui.util';
import { CloseIcon } from '../../resources/fonts/icons'; // Import CloseIcon
import './modal.component.scss';

/**
 * Modal Component
 */
const Modal = ({ children, className, fullscreen, animation, onHide, title, isVisible, disableEscToClose, backButton }) => {
  const [isBeingHidden, setIsBeingHidden] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [opacity, setOpacity] = useState(1);
  
  const touchStart = useRef(0);
  const currentTouch = useRef(0);
  const lastTime = useRef(0);
  const lastTranslateY = useRef(0);
  const velocity = useRef(0);
  const containerRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    if (isVisible) {
      setIsMounted(true);
      // Small delay to ensure mount is complete before animations
      setTimeout(() => {
        setIsBeingHidden(false);
        setTranslateY(0);
        setOpacity(1);
      }, 50);
    } else {
      setIsBeingHidden(true);
      // Wait for hiding animation before unmounting
      setTimeout(() => {
        setIsMounted(false);
        setIsBeingHidden(false);
      }, 350);
    }
  }, [isVisible]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.key === 'Escape' || event.key === 'Esc') && !disableEscToClose) {
        onHide();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [disableEscToClose, onHide]);

  useEffect(() => {
    if (isMounted) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isMounted]);

  if (!isMounted) return null;

  const baseClassName = getClassName('modal', [
    { condition: className, trueClassName: className },
    { condition: fullscreen, trueClassName: 'modal--fullscreen' },
    { condition: animation, trueClassName: `modal--${animation}` },
    { condition: isBeingHidden, trueClassName: 'modal--hiding' }
  ]);

  const handleMaskClick = (event) => {
    if (event.target.classList.contains('modal__mask') || event.target.classList.contains('modal__scroll-container')) {
      onHide();
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    if (touch.target.closest('.modal__header')) {
      isDragging.current = true;
      touchStart.current = touch.clientY;
      currentTouch.current = touch.clientY;
      lastTime.current = Date.now();
      lastTranslateY.current = translateY;
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    
    const touch = e.touches[0];
    const deltaY = touch.clientY - touchStart.current;
    const newTranslateY = Math.max(0, deltaY);
    
    // Calculate velocity
    const now = Date.now();
    const dt = now - lastTime.current;
    if (dt > 0) {
      velocity.current = (touch.clientY - currentTouch.current) / dt;
    }
    
    lastTime.current = now;
    currentTouch.current = touch.clientY;
    
    setTranslateY(newTranslateY);
    setOpacity(Math.max(0.5, 1 - newTranslateY / 400));
  };

  const handleTouchEnd = () => {
    if (!isDragging.current) return;
    isDragging.current = false;
    
    const threshold = window.innerHeight * 0.2;
    const shouldClose = translateY > threshold || velocity.current > 0.5;
    
    if (shouldClose) {
      setTranslateY(window.innerHeight);
      setOpacity(0);
      // Increase timeout to ensure animation completes
      setTimeout(() => {
        onHide();
      }, 300);
    } else {
      setTranslateY(0);
      setOpacity(1);
    }
  };

  const containerStyle = {
    transform: window.innerWidth <= 600 
      ? `translateY(${translateY}px)` 
      : 'none',
    transition: isDragging.current ? 'none' : 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
  };

  const maskStyle = {
    opacity: opacity,
    transition: isDragging.current ? 'none' : 'opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
  };

  return ReactDOM.createPortal(
    <div className={baseClassName}>
      <div className='modal__mask' onClick={handleMaskClick} style={maskStyle} />
      <div className="modal__scroll-container" onClick={handleMaskClick}>
        <div 
          ref={containerRef}
          className='modal__container'
          style={containerStyle}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className='modal__header'>
            <div className='modal__drag-indicator' />
            { backButton && (
              <div className="modal__back-button" onClick={backButton.onClick} style={{ cursor: 'pointer' }}>
                { backButton.icon }
              </div>
            )}
            <div className='modal__title'>{title}</div>
            <div className='modal__close-container'> {/* Add container for CloseIcon */}
              <CloseIcon onClick={onHide} className='modal__close-button' />
            </div>
          </div>
          <div className='modal__children'>
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  animation: PropTypes.oneOf(['fade', 'slide-up']),
  fullscreen: PropTypes.bool,
  disableEscToClose: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  backButton: PropTypes.shape({
    icon: PropTypes.node,
    onClick: PropTypes.func,
  })
};

Modal.defaultProps = {
  animation: 'fade',
  disableEscToClose: false
};

export default Modal;