// src/config.js

const common = {
  STRIPE_BUY_URL: 'https://buy.stripe.com/dR68A982i56u6oE288',
  STRIPE_CUSTOMER_PORTAL: 'https://billing.stripe.com/p/login/aEUcP86qNe2v48g144',
  GOOGLE_API_KEY: 'G-CMBXR1NSKH',
  APP_VERSION: '2.0.0',
  HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
};

const environments = {
  production: {
    ...common,
    API_URL: 'https://tattle-web-api-bc2bb3614633.herokuapp.com/api',
    BASE_URL: 'https://www.tattlenews.com',
    HEADERS: {
      ...common.HEADERS,
      'api-key': 'd0c0f218-3793-47bc-8156-48dd940274dd',
    },
    DISCORD_OAUTH2_URL:
      'https://discord.com/oauth2/authorize?client_id=671176256313622548&response_type=code&redirect_uri=https%3A%2F%2Fwww.tattlenews.com%2Foauth2%2Fdiscord&scope=identify+email',
    GOOGLE_OAUTH2_URL:
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?client_id=${process.env.GOOGLE_CLIENT_ID}` +
      `&response_type=code` +
      `&scope=email profile` +
      `&redirect_uri=https://tattlenews.com/oauth2/google` // Production callback
  },
  development: {
    ...common,
    API_URL: 'https://tattle-web-api-dev-0b2e176ae184.herokuapp.com/api',
    BASE_URL: 'https://develop.tattlenews.com',
    HEADERS: {
      ...common.HEADERS,
      'api-key': '5433eb58-ef7c-4736-842d-84a0c537c6dd',
    },
    DISCORD_OAUTH2_URL:
      'https://discord.com/oauth2/authorize?client_id=759597667918807060&response_type=code&redirect_uri=https%3A%2F%2Fdevelop.tattlenews.com%2Foauth2%2Fdiscord&scope=identify+email',
    GOOGLE_OAUTH2_URL:
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?client_id=353602861874-rqeo5hrodiea5t775ljilkppfvltua2b.apps.googleusercontent.com` +
      `&response_type=code` +
      `&scope=email profile` +
      `&redirect_uri=https://develop.tattlenews.com/oauth2/google` // Dev callback
  },
  local: {
    ...common,
    API_URL:  'https://tattle-web-api-dev-0b2e176ae184.herokuapp.com/api',//  'http://localhost:3001/api',
    BASE_URL: 'http://localhost:3000',
    HEADERS: {
      ...common.HEADERS,
      'api-key': '5433eb58-ef7c-4736-842d-84a0c537c6dd',
    },
    DISCORD_OAUTH2_URL:
      'https://discord.com/oauth2/authorize?client_id=759597667918807060&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foauth2%2Fdiscord&scope=identify+email',
    GOOGLE_OAUTH2_URL:
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?client_id=353602861874-rqeo5hrodiea5t775ljilkppfvltua2b.apps.googleusercontent.com` +
      `&response_type=code` +
      `&scope=email profile` +
      `&redirect_uri=http://localhost:3000/oauth2/google` // Dev callback
  },
};

const config = environments.development;
export default config;
