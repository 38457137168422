import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FeedSearch from '../feedSearch/feedSearch.component';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { clearSearchQuery } from '../../reducers/searchSlice';
import TimeFrameDropdown from '../dropDownMenu/timeFrameDropdown/dropDownFilterMenu';
import { updateActiveTag } from '../../reducers/tagSlice';
import './tagFilter.css';
import ScrollableNav from '../scrollableNav/scrollableNav';

const TagFilter = ({ activeTag, pageType }) => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.tag.tags);
  const searchQuery = useSelector((state) => state.search?.query || '');
  const location = useLocation();

  const handleTagFilter = useCallback(
    (tag) => {
      if (tag.toLowerCase() === activeTag.toLowerCase()) {
        if (tag.toLowerCase() !== 'all') {
          dispatch(updateActiveTag('All'));
        }
      } else {
        dispatch(updateActiveTag(tag));
      }
    },
    [dispatch, activeTag]
  );

  useEffect(() => {
    dispatch(clearSearchQuery());
    dispatch(updateActiveTag('All'));
  }, [location, dispatch]);

  return (
    <div className="tag-filter-wrapper">
      <div className="tagBarContainer">
        <ScrollableNav>
          <div className="tag-filter-container">
            <TimeFrameDropdown pageType={pageType} />
            {tags.map((tag) => (
              <button
                key={tag}
                className={classnames('tag-filter-button', { 
                  active: tag.toLowerCase() === activeTag.toLowerCase() 
                })}
                onClick={() => handleTagFilter(tag)}
                aria-pressed={tag.toLowerCase() === activeTag.toLowerCase()}
              >
                {tag}
              </button>
            ))}
          </div>
        </ScrollableNav>
        <div className="feed-search-wrapper">
          <FeedSearch />
        </div>
      </div>
      {searchQuery && (
        <div className="search-results-wrapper">
          <div className="search-results-container">
            {`Search results for: "${searchQuery}"`}
          </div>
        </div>
      )}
    </div>
  );
};

TagFilter.propTypes = {
  activeTag: PropTypes.string.isRequired,
};

export default React.memo(TagFilter);
