import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getClassName } from '../../utils/ui/ui.util';
import './viewSwitch.component.css';

/**
 * ViewSwitch Component
 */
const ViewSwitch = ({ views, activeViewId, rememberMounted }) => {
  const activeViewIndex = (views || []).findIndex((view) => view.id === activeViewId);
  const [mountedIndexes, setMountedIndexes] = useState(
    typeof activeViewIndex !== 'undefined' ? [activeViewIndex] : []
  );

  useEffect(() => {
    handleViewChange(activeViewId);
  }, [activeViewId]);

  const handleViewChange = (newActiveViewId) => {
    const newActiveViewIndex = views.findIndex((view) => view.id === newActiveViewId);

    if (rememberMounted && mountedIndexes.indexOf(newActiveViewIndex) < 0) {
      setMountedIndexes((prevMountedIndexes) => [...prevMountedIndexes, newActiveViewIndex]);
    }
  };

  const renderedViews = useMemo(() => {
    if (rememberMounted) {
      return views.map((view, index) => {
        if (rememberMounted && !mountedIndexes.includes(index)) {
          return null;
        }

        const viewClassName = getClassName('view-switch_item', [
          { condition: view.id === activeViewId, trueClassName: 'view-switch_item--active' }
        ]);

        return (
          <div key={`view-switch_item--${view.id}`} className={viewClassName}>
            {typeof view.component === 'function' ? view.component() : view.component}
          </div>
        );
      });
    } else {
      const activeView = views.find((view) => view.id === activeViewId);
      return (
        <div className="view-switch_item view-switch_item--standalone">
          {typeof activeView.component === 'function' ? activeView.component() : activeView.component}
        </div>
      );
    }
  }, [views, activeViewId, rememberMounted, mountedIndexes]);

  if (!views || !views.length || !activeViewId) {
    return null;
  }

  return (
    <section className="view-switch">
      {renderedViews}
    </section>
  );
};

ViewSwitch.propTypes = {
  views: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    })
  ).isRequired,
  activeViewId: PropTypes.string.isRequired,
  rememberMounted: PropTypes.bool,
};

ViewSwitch.defaultProps = {
  rememberMounted: true,
};

export default React.memo(ViewSwitch);
