import React, { useState, useRef, useEffect, useCallback } from 'react';
import { SearchIcon, CloseIcon } from '../../resources/fonts/icons';
import Button from '../button/button.component';
import useSearch from '../../hooks/useSearch';
import './feedSearch.component.css';
import classnames from 'classnames';

const FeedSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { performSearch, clearSearch: clearAppSearch } = useSearch();

  const inputRef = useRef(null);

  useEffect(() => {
    if (isSearchVisible && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [isSearchVisible]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    performSearch(value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    clearAppSearch();
  };

  const handleSearchIconClick = useCallback(() => {
    if (searchTerm.trim()) {
      performSearch(searchTerm.trim());
    } else {
      setIsSearchVisible((prev) => {
        const newState = !prev;
        if (!newState) {
          clearSearch();
        }
        return newState;
      });
    }
  }, [searchTerm, performSearch, clearSearch]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      clearSearch();
      e.stopPropagation();
    }
    // Allow default behavior for space key
    if (e.key === ' ') {
      // Do not prevent default to allow space navigation
      return;
    }
    // ...existing code...
  };

  return (
    <div className="feedSearchContainer">
      <div className={classnames('searchInputWrapper', { active: isSearchVisible })}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            handleKeyDown(e);
            if (e.key === 'Enter') {
              inputRef.current.blur();
            }
          }}
          placeholder="Search feed"
          aria-label="Search input"
          className="feedSearchInput"
          autoFocus={isSearchVisible}
          ref={inputRef}
        />
        {searchTerm && (
          <div className="feedSearchCloseContainer">
            <Button
              onClick={clearSearch}
              aria-label="Clear search"
              className="feedSearchCloseButton"
              icon={<CloseIcon />}
              modifiers={{ disabled: false }}
              styles={{
                base: {},
                icon: {},
              }}
            />
          </div>
        )}
        <div className="feedSearchIconContainer">
          <Button
            onClick={handleSearchIconClick}
            aria-label="Search"
            variant="icon"  // Use the icon variant
            className="feedSearchIconButton"
            icon={<SearchIcon />}
            modifiers={{ disabled: false }}
            styles={{
              base: {},
              icon: {},
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedSearch;
