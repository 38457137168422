import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  category: 'all',
  search: '',
  topicId: undefined,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCategory(state, action) {
      state.category = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    setTopicId(state, action) {
      state.topicId = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const { setCategory, setSearch, setTopicId, resetFilters } = filterSlice.actions;
export default filterSlice.reducer;
