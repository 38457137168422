import React from 'react';
import Footer from '../../components/footer/footer.component';
import './sidebar.container.scss';

const SidebarContainer = () => {
  return (
    <div className="sidebar-container">
      <div className="sidebar-content">
        {/* Add any sidebar content here */}
      </div>
      <Footer />
    </div>
  );
};

export default SidebarContainer;