import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { searchBookmarks } from './bookmarksSlice';
import { searchHistory } from './historySlice';
import { handleError } from '../utils/errors/errorHandler';

export const searchForTerm = (term) => (dispatch) => {
  dispatch(updateSearchQuery(term));
  dispatch(searchBookmarks(term));
  dispatch(searchHistory(term));
};

export const searchTopics = createAsyncThunk(
  'search/searchTopics',
  async (term, { getState, dispatch, rejectWithValue }) => {
    try {
      // Update the global search query
      dispatch(updateSearchQuery(term));

      // Filter categories from state.categories.data
      const { categories } = getState();
      if (!categories || !Array.isArray(categories.data)) {
        return [];
      }

      const lowerTerm = term.trim().toLowerCase();
      if (!lowerTerm) {
        // If search is empty, return everything
        return categories.data;
      }

      // Filter category topics locally
      const filtered = categories.data
        .map((cat) => ({
          ...cat,
          topics: cat.topics.filter((topic) =>
            topic.label.toLowerCase().includes(lowerTerm)
          ),
        }))
        .filter((cat) => cat.topics.length > 0);

      return filtered;
    } catch (error) {
      const errMsg = handleError(error, 'searchTopics');
      return rejectWithValue(errMsg);
    }
  }
);

const initialState = {
  query: '',
  filteredCategories: [],
  searchedTopics: [], // Add this property
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchQuery(state, action) {
      state.query = action.payload;
    },
    clearSearchQuery(state) {
      state.query = '';
      state.filteredCategories = [];
      state.searchedTopics = [];
    },
    filterTopicsLocally(state, action) {
      const { topics, term } = action.payload;
      if (!term) {
        state.searchedTopics = topics;
      } else {
        const lowerTerm = term.toLowerCase();
        state.searchedTopics = topics.filter((topic) =>
          topic.label.toLowerCase().includes(lowerTerm)
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTopics.fulfilled, (state, action) => {
        state.filteredCategories = action.payload;
      })
      .addCase(searchTopics.rejected, (state) => {
        // Optionally clear or show error
        state.filteredCategories = [];
      });
  },
});

export const {
  updateSearchQuery,
  clearSearchQuery,
  filterTopicsLocally,
} = searchSlice.actions;

export default searchSlice.reducer;
