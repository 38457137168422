import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import './topNavBar.scss';
import NewArticlesIndicator from '../newArticlesIndicator/newArticlesIndicator.component';

const TopNavBar = ({ navbar, tagFilter, newIndicatorProps }) => {
  const scrollDirection = useScrollDirection();
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    setAnimationClass(scrollDirection === 'down' ? 'sliding-out' : 'sliding-in');
  }, [scrollDirection]);

  const navVisible = animationClass !== 'sliding-out';

  return (
    <div className="topnav-sticky-container">
      <div className={`top-navbar-wrapper ${animationClass}`}>
        {navbar}
        {tagFilter}
      </div>
      <NewArticlesIndicator
        {...newIndicatorProps}
        navbarVisible={navVisible}
      />
    </div>
  );
};

TopNavBar.propTypes = {
  navbar: PropTypes.element.isRequired,
  tagFilter: PropTypes.element.isRequired,
};

export default TopNavBar;
