import React from 'react';
import './textField.component.scss';

const TextField = ({
  id,
  name,
  type = 'text',
  value,
  placeholder,
  required,
  onChange,
}) => {
  const handleAutoResize = (el) => {
    el.style.height = 'auto';
    el.style.height = `${el.scrollHeight}px`;
  };

  return (
    <div className="text-field">
      {/* ...label if needed... */}
      {type === 'textarea' ? (
        <textarea
          id={id}
          name={name}
          required={required}
          placeholder={placeholder}
          value={value}
          onInput={(e) => {
            handleAutoResize(e.target);
            onChange(e);
          }}
        />
      ) : (
        <input
          id={id}
          name={name}
          type={type}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default TextField;