// src/reducers/subscriptionsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/axiosInstance';
import AuthService from '../services/auth.service';
import { handleError } from '../utils/errors/errorHandler';

/** Fetch User Subscriptions */
export const fetchUserSubscriptions = createAsyncThunk(
  'subscriptions/fetchUserSubscriptions',
  async ({ profileId, params }, { rejectWithValue }) => {
    try {
      if (!AuthService.isAuthenticated()) {
        return rejectWithValue('User is not authenticated');
      }

      if (!profileId) {
        return rejectWithValue('Profile ID not provided');
      }

      const query = params ? `?${new URLSearchParams(params).toString()}` : '';
      const response = await api.get(`/private/subscription?profile=${profileId}${query}`);

      if (!Array.isArray(response.data.data)) {
        return rejectWithValue('Invalid subscription data format');
      }

      const formattedData = response.data.data.map((subscription) => ({
        ...subscription,
        topic: subscription._topic || {}, // Corrected mapping from _topic to topic
      }));

      return formattedData;
    } catch (error) {
      const errorMessage = handleError(error, 'fetchUserSubscriptions');
      return rejectWithValue(errorMessage);
    }
  }
);

/** Subscribe/Unsubscribe to a Topic */
export const toggleSubscriptionStatus = createAsyncThunk(
  'subscriptions/toggleSubscriptionStatus',
  async ({ subscriptionTopicId, profileId, actionType }, { dispatch, rejectWithValue }) => {
    try {
      if (!AuthService.isAuthenticated()) {
        return rejectWithValue('User is not authenticated');
      }

      const body = { topic: subscriptionTopicId, profile: profileId, action: actionType };
      const response = await api.post(`/private/topic`, body);

      const topicData = response.data.data;

      if (!topicData || !topicData._id) {
        return rejectWithValue('Invalid toggle subscription response');
      }

      // After toggling, fetch the latest subscriptions from the server
      await dispatch(fetchUserSubscriptions({ profileId }));

      return { subscriptionTopicId, actionType };
    } catch (error) {
      const errorMessage = handleError(error, 'toggleSubscriptionStatus');
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial state
const initialState = {
  subscriptions: [],
  status: 'idle',
  error: null,
};

// Subscriptions slice
const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    resetSubscriptionsState: () => initialState,
    // ...existing reducers...
  },
  extraReducers: (builder) => {
    builder
      // Fetch User Subscriptions
      .addCase(fetchUserSubscriptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserSubscriptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subscriptions = action.payload;
        state.error = null;
      })
      .addCase(fetchUserSubscriptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = handleError(action.error, 'fetchUserSubscriptions');
      })
      // Toggle Subscription Status
      .addCase(toggleSubscriptionStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toggleSubscriptionStatus.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(toggleSubscriptionStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = handleError(action.error, 'toggleSubscriptionStatus');
      });
    // Removed problematic comments to maintain proper chaining
  },
});

// Selectors
export const selectSubscriptions = (state) => state.subscriptions.data;

// Export actions and reducer
export const { resetSubscriptionsState } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;

// ...existing code...

// Remove the duplicate fetchUserSubscriptions thunk definition
// export const fetchUserSubscriptions = createAsyncThunk(
//   'subscriptions/fetchUserSubscriptions',
//   async (_, { dispatch, getState, rejectWithValue }) => {
//     // ...implementation if split...
//   }
// );

// ...existing code...
