import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ArticleItem from '../articleItem/articleItem.component';
import LoadingMask from '../../loadingMask/loadingMask.component';
import useScrollHandler from '../../../hooks/useScrollHandler';
import { useClickedArticles } from '../../../hooks/useClickedArticles'; // Add this import
import './articleList.component.scss';

const ArticleList = forwardRef(({
  articles,
  showName = false,
  fetchMore,
  hasMore = true,
  hideScrollTrigger = false,
  isInTopicPage,
}, ref) => {
  const scrollTriggerRef = useScrollHandler({ fetchMore, hasMore });
  const mountedRef = useRef(true);

  // Combine related selectors
  const status = useSelector(state => state.articles.listStatus);

  const { clickedArticleIds } = useClickedArticles(); // Add this line

  useEffect(() => {
    mountedRef.current = true;
    
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useImperativeHandle(ref, () => ({
    resetList: () => {
      if (mountedRef.current) {
        if (scrollTriggerRef.current) {
          scrollTriggerRef.current.style.display = 'block';
        }
      }
    },
  }));

  const displayArticles = articles;

  return (
    <>
      {status === 'loading' && articles.length === 0 && (
        <div className="article__spinner-center">
          <LoadingMask includeSpinner={true} includeOverlay={false} />
        </div>
      )}
      <ul className="article__list">
        {displayArticles.length > 0 ? (
          displayArticles.map((article, index) => {
            if (!article || !article._id) {
              return (
                <li key={`invalid-article-${index}`} className="article__no-data">
                  Invalid article data.
                </li>
              );
            }

            return (
              <ArticleItem
                key={`article-${article._id}-${index}`}
                article={article}
                showName={showName}
                isInTopicPage={isInTopicPage}
                isClicked={clickedArticleIds.includes(article._id)} // Add this prop
              />
            );
          })
        ) : (
          status !== 'loading' && (
            <li className="article__no-data">No articles available.</li>
          )
        )}
        {hasMore && !hideScrollTrigger && (
          <li 
            ref={scrollTriggerRef}
            className={`scroll-trigger`}
            data-testid="scroll-trigger"
            style={{ 
              height: '20px',
              opacity: 1,
              pointerEvents: 'auto'
            }}
          >
            {status === 'loading' && articles.length > 0 && (
              <div className="article__spinner-inline">
                <LoadingMask includeSpinner={true} includeOverlay={false} />
              </div>
            )}
          </li>
        )}
      </ul>
    </>
  );
});

// Update propTypes to work with forwardRef
const propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      images: PropTypes.array,
      publish_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      feed_link: PropTypes.object,
      _topic: PropTypes.object,
      content_text: PropTypes.string,
    })
  ).isRequired,
  showName: PropTypes.bool,
  fetchMore: PropTypes.func,
  hasMore: PropTypes.bool,
  hideScrollTrigger: PropTypes.bool,
  isInTopicPage: PropTypes.bool,
};

// Update defaultProps to work with forwardRef
const defaultProps = {
  articles: [],
  showName: false,
  fetchMore: null,
  hasMore: true,
  hideScrollTrigger: false,
  isInTopicPage: false,
};

// Assign propTypes and defaultProps
ArticleList.propTypes = propTypes;
ArticleList.defaultProps = defaultProps;

// Export the forwardRef component
export default React.memo(ArticleList);
