
import { useState, useCallback } from 'react';

function useDebounce(callback, delay) {
  const [timerId, setTimerId] = useState(null);

  const debouncedCallback = useCallback(
    (...args) => {
      if (timerId) clearTimeout(timerId);
      const newTimerId = setTimeout(() => {
        callback(...args);
      }, delay);
      setTimerId(newTimerId);
    },
    [callback, delay, timerId]
  );

  return debouncedCallback;
}

export default useDebounce;