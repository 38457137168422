// src/utils/reduxUtils.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from '../errors/errorHandler';

/**
 * Helper function to handle extraReducers uniformly.
 */
export const handleAsync = (builder, thunk, onFulfilled) => {
  builder
    .addCase(thunk.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    .addCase(thunk.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (onFulfilled) {
        onFulfilled(state, action);
      }
      state.error = null;
    })
    .addCase(thunk.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
};

/**
 * Helper function to create a thunk with error handling.
 */
export const createThunkWithErrorHandler = (typePrefix, payloadCreator) => {
  return createAsyncThunk(typePrefix, async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      return await payloadCreator(args, thunkAPI);
    } catch (error) {
      const errorMessage = handleError(error, typePrefix);
      return rejectWithValue(errorMessage);
    }
  });
};
