// src/services/api.service.js

import axios from 'axios';
import AuthService from '../services/auth.service';
import config from '../config';
import { handleError } from '../utils/errors/errorHandler'; // Ensure handleError is imported
import store from '../store'; // Or wherever your Redux store is
import { setApiMessage } from '../reducers/apiMessageSlice'; 

const { API_URL, HEADERS: defaultHeaders } = config;

// Create an Axios instance
const api = axios.create({
  baseURL: API_URL, // Ensure API_URL is correct, e.g., 'https://tattle-web-api-dev-0b2e176ae184.herokuapp.com/api'
  headers: {
    ...defaultHeaders,
    'Content-Type': 'application/json',
  },
});

// Handle CORS if server allows
api.interceptors.request.use(
  async (config) => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    if (AuthService.isLoggedOut) {
      return config;
    }

    if (AuthService.isAuthenticated()) {
      const token = await AuthService.getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    handleError(error, 'API Request');
    return Promise.reject(error);
  }
);

// Flag to prevent multiple logout calls
let isLoggingOut = false;

// Response interceptor to handle 401 errors and extract server messages
api.interceptors.response.use(
  (response) => {
    const serverMessage = response.data?.message;
    if (serverMessage) {
      store.dispatch(setApiMessage(serverMessage));
    }
    return response;
  },
  async (error) => {
    // Remove duplicate declaration and use a single variable.
    const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
    if (error.response?.data?.message) {
      store.dispatch(setApiMessage(error.response.data.message));
    }
    handleError(error, 'API Response');
    
    // Attach the error message to the error object
    error.serverMessage = errorMessage;

    const originalRequest = error.config;
    // ...existing retry logic...
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url.includes('/private') &&
      !AuthService.isLoggedOut
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await AuthService.refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        if (!isLoggingOut) {
          isLoggingOut = true;
          AuthService.handleLogout();
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
