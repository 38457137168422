/**
 * Returns link from article
 *
 * @param article
 * @returns {string|boolean}
 */
export const getLnk = (article) => {
  let link = '';

  if (article.article_link && article.article_link.length) {
    link = article.article_link[0].href
  } else if (article.shortcut_url && article.shortcut_url.length) {
    if (article.shortcut_url.length > 1) {
      article.shortcut_url.forEach((url) => {
        if (url.rel === 'alternate') {
          link = url.href
        }
      })
    } else {
      link = article.shortcut_url[0].href
    }
  }

  return link
}

/**
 * Generates share text based on type, title, and content.
 *
 * @param {string} type - Type of share (e.g., 'article', 'topic')
 * @param {string} title - Title of the article or topic
 * @param {string} content - Content of the article
 * @returns {string} - The generated share text
 */
export const generateShareText = (type, title, content) => {
  let text = '';
  if (type === 'article') {
    text = `${title}\n\n`;
    if (content) {
      content = content.replace(/[^a-zA-Z :!?'",`’]/g, '');
      text += `${content}...\n\n`;
    }
  } else {
    text = `Topic: ${title}\n\n`;
  }
  return text;
};
