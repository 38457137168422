// src/components/subscriptionButton/SubscriptionButton.jsx

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button.component';
import useSubscription from '../../hooks/useSubscription';
import './subscriptionButton.css';

const SubscriptionButton = ({
  subscriptionTopicId,
  subscribed: initialSubscribed,
  onUnsubscribe,
  onClickOnly = false,
}) => {
  const {
    isSubscribed,
    isAuthenticated,
    isHovered,
    handleMouseEnter,
    handleMouseLeave,
    onToggleSubscription,
  } = useSubscription({
    subscriptionTopicId,
    initialSubscribed,
    onUnsubscribe,
    onClickOnly,
  });

  // Determine button label and class based on subscription status and hover
  let buttonLabel = 'Subscribe';
  let buttonClass = 'subscribe-button';

  if (isAuthenticated) {
    const subscribedStatus = onClickOnly ? initialSubscribed : isSubscribed; // Use local or global state based on mode

    if (subscribedStatus) {
      buttonLabel = isHovered ? 'Unsubscribe' : 'Subscribed';
      buttonClass = 'subscribed-button';
    }
  }

  return (
    <>
      <Button
        label={buttonLabel}
        className={buttonClass}
        onClick={onToggleSubscription}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={false}
      />
    </>
  );
};

SubscriptionButton.propTypes = {
  subscriptionTopicId: PropTypes.string.isRequired,
  subscribed: PropTypes.bool,
  onUnsubscribe: PropTypes.func,
  onClickOnly: PropTypes.bool,
};

export default SubscriptionButton;
