export const jumpToTop = (duration = 600) => {
  const start = window.pageYOffset;
  const startTime = performance.now();

  const easeOutQuad = (t) => t * (2 - t);

  const scroll = () => {
    const now = performance.now();
    const time = Math.min(1, (now - startTime) / duration);
    const easedTime = easeOutQuad(time);
    window.scrollTo(0, Math.ceil((1 - easedTime) * start));
    if (window.pageYOffset > 0) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
