import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navigation.container.scss';
import './navigation.mobile.scss';
import {
  HomeIconOutlined,
  HomeIconFilled,
  TrendingIconOutlined,
  TrendingIconFilled,
  ExploreIconOutlined,
  ExploreIconFilled,
  AccountIconOutlined,
  AccountIconFilled,
  DiscordIconOutlined,
  DiscordIconFilled,
  TattleLogoIcon,
  TattleBetaType,
  CollectionsBookmarkIconOutlined,
  CollectionsBookmarkIconFilled,
} from '../../resources/fonts/icons';
import { useSelector } from 'react-redux';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import EventEmitter from 'events';
import SignInHandler from '../../components/authentication/authTrigger/authTrigger';

// Export auth event emitter for app-wide use
export const authEventEmitter = new EventEmitter();

const NavItem = ({ to, label, iconOutlined, iconFilled, secondaryIcon, isVisible, onClick, className }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);

  if (!isVisible) return null;

  const handleClick = (e) => {
    if (isActive) {
      authEventEmitter.emit('refreshFeed');
      e.preventDefault();
    }
    if (onClick) onClick(e);
  };

  return (
    <Link to={to} className={`nav-item-container ${className || ''}`} onClick={handleClick}>
      <div className={`nav-item ${isActive ? 'active' : ''}`}>
        <div className="nav-item-icon-container">
          <div className="nav-item-icon">
            {isActive ? iconFilled : iconOutlined}
          </div>
          {secondaryIcon && (
            <div className="nav-secondary-icon">
              {secondaryIcon}
            </div>
          )}
        </div>
        {label && (
          <div className="nav-item-text">
            {label}
          </div>
        )}
      </div>
    </Link>
  );
};

const NavigationContainer = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [isAtTop, setIsAtTop] = useState(true);
  const scrollDirection = useScrollDirection();

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle scroll to determine if at top
  useEffect(() => {
    const handleScroll = () => setIsAtTop(window.scrollY === 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = useMemo(() => [
    {
      to: '/home',
      label: '',
      iconOutlined: <TattleLogoIcon className="nav-logo-icon" />,
      iconFilled: <TattleLogoIcon className="nav-logo-icon" />,
      secondaryIcon: <TattleBetaType className="nav-beta-logo-icon" />,
      isVisible: !isMobile,
      className: 'nav-logo-container',
    },
    {
      to: isAuthenticated ? '/home' : '/trending',
      label: 'Home',
      iconOutlined: <HomeIconOutlined />,
      iconFilled: <HomeIconFilled />,
      isVisible: isAuthenticated,
    },
    {
      to: '/trending',
      label: 'Trending',
      iconOutlined: <TrendingIconOutlined />,
      iconFilled: <TrendingIconFilled />,
      isVisible: true,
    },
    {
      to: '/explore',
      label: 'Explore',
      iconOutlined: <ExploreIconOutlined />,
      iconFilled: <ExploreIconFilled />,
      isVisible: true,
    },
    {
      to: isAuthenticated ? '/library' : '/trending',
      label: 'Library',
      iconOutlined: <CollectionsBookmarkIconOutlined />,
      iconFilled: <CollectionsBookmarkIconFilled />,
      isVisible: isAuthenticated,
    },
    {
      to: '/bot',
      label: 'Tattle Bot',
      iconOutlined: <DiscordIconOutlined />,
      iconFilled: <DiscordIconFilled />,
      isVisible: !isMobile || (!isAuthenticated && isMobile),
    },
    {
      to: isAuthenticated ? '/settings' : '/trending',
      label: 'Profile',
      iconOutlined: <AccountIconOutlined className="nav-item-icon" />,
      iconFilled: <AccountIconFilled className="nav-item-icon" />,
      isVisible: isAuthenticated,
    },
  ], [isAuthenticated, isMobile]);

  return (
    <>
      <div className={`navigation-wrapper ${isAtTop ? 'at-top' : scrollDirection === 'down' ? 'scrolling-down' : ''}`}>
        <div className={`navigation-container ${isMobile ? 'mobile' : 'desktop'}`}>
          {navItems.map((item, index) => (
            <NavItem key={index} {...item} />
          ))}
          <SignInHandler
            onRenderSignInButton
            SignInIcon={<AccountIconOutlined />}
          />
        </div>
      </div>
    </>
  );
};

export default NavigationContainer;
