import React, { useState } from 'react'; // Import useState
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton, RedditShareButton } from 'react-share';
import { FacebookIcon, XIcon, RedditIcon } from 'react-share';
import { Modal } from '../../components'; // Import the centralized Modal component
import config from '../../config';
import { LinkIcon, MoreIcon } from '../../resources/fonts/icons'; // Import LinkIcon and MoreIcon
import './share.component.scss';
import { generateShareText } from '../../utils/article/article.util'; // Import generateShareText

/**
 * ShareComponent to handle sharing functionality.
 */
const ShareComponent = ({ isVisible, handleShareClose, type, url, title, content }) => {
  const [isTitleCopied, setIsTitleCopied] = useState(false); // Add state for title copied
  const [isLinkCopied, setIsLinkCopied] = useState(false); // Add state for link copied

  const shareUrl = url || `${config.BASE_URL}/default-path`; // Ensure url is set
  const shareText = generateShareText(type, title, content);

  const handleCopyLink = () => {
    if (navigator?.clipboard && shareUrl) {
      navigator.clipboard.writeText(shareUrl);
      setIsLinkCopied(true);
      setTimeout(() => setIsLinkCopied(false), 2000); // Reset after 2 seconds
    }
  };

  const handleTitleClick = () => {
    if (navigator?.clipboard && title) {
      navigator.clipboard.writeText(title);
      setIsTitleCopied(true);
      setTimeout(() => setIsTitleCopied(false), 2000); // Reset after 2 seconds
    }
  };

  const handleMoreShare = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: shareText,
        url: shareUrl,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback if Web Share API is not supported
      alert('Sharing not supported on this browser.');
    }
  };

  if (!isVisible) return null;

  return (
    <Modal
      isVisible={isVisible}
      onHide={handleShareClose}
      title={`Share ${type}`}
    >
      <div className="share-modal-content">
        {/* Removed IconButton and CloseIcon */}
        <div className="share-modal-body">
          {isTitleCopied && <div className="copied-dialog">Title copied</div>}
          <div
            className={`share-article-title ${isTitleCopied ? 'copied' : ''}`}
            onClick={handleTitleClick} // Add onClick handler
          >
            {title}
          </div>
          <div className="share-actions">
            <div className="share-action">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              <p className="share-label">Facebook</p>
            </div>
            <div className="share-action">
              <TwitterShareButton url={shareUrl} title={shareText}>
                <XIcon size={40} round />
              </TwitterShareButton>
              <p className="share-label">Twitter</p>
            </div>
            <div className="share-action">
              <RedditShareButton url={shareUrl} title={title}>
                <RedditIcon size={40} round />
              </RedditShareButton>
              <p className="share-label">Reddit</p>
            </div>
            <div className="share-action" onClick={handleCopyLink}>
              <div className={`link-icon-container ${isLinkCopied ? 'copied' : ''}`}>
                <LinkIcon />
              </div>
              <p className="share-label">Copy Link</p>
            </div>
            <div className="share-action more-share-action" onClick={handleMoreShare}>
              <div className={`link-icon-container`}> {/* Added container for styling */}
                <MoreIcon />
              </div>
              <p className="share-label">More</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ShareComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  handleShareClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

ShareComponent.defaultProps = {
  isVisible: false,
};

export default ShareComponent;
