// scrollableRow.js
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, ArrowBackIcon } from '../../resources/fonts/icons';
import useScrollableNav from '../../hooks/useScrollableNav';
import './scrollableRow.css';

// Ensure no imports related to topics, explore page, or categories are present.

const ScrollableRow = ({ children, onDraggingChange, disableDragScrolling }) => { // Added disableDragScrolling
  const scrollBarRef = useRef(null);
  const {
    handleScroll,
    handleStartDrag,
    startContinuousScroll,
    stopContinuousScroll,
    dragging, // Existing dragging state
    showLeftButton,
    showRightButton,
  } = useScrollableNav(scrollBarRef);

  useEffect(() => {
    if (onDraggingChange) {
      onDraggingChange(dragging); // Notify parent of dragging state
    }
  }, [dragging, onDraggingChange]);

  const scrollTimeoutRef = useRef(null);
  const isHoldingRef = useRef(false);

  const handlePressStart = (direction) => {
    scrollTimeoutRef.current = setTimeout(() => {
      isHoldingRef.current = true;
      startContinuousScroll(direction);
    }, 300);
  };

  const handlePressEnd = (direction) => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = null;
    }
    if (isHoldingRef.current) {
      isHoldingRef.current = false;
      stopContinuousScroll();
    } else {
      handleScroll(direction);
    }
  };

  const handlePressLeave = () => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = null;
    }
    if (isHoldingRef.current) {
      isHoldingRef.current = false;
      stopContinuousScroll();
    }
  };

  return (
    <div className="scrollableRow">
      {showLeftButton && (
        <button
          className="scroll-button left"
          onMouseDown={() => handlePressStart('left')}
          onMouseUp={() => handlePressEnd('left')}
          onMouseLeave={handlePressLeave}
          onTouchStart={() => handlePressStart('left')}
          onTouchEnd={() => handlePressEnd('left')}
          onTouchCancel={handlePressLeave}
          aria-label="Scroll Left"
        >
          <ArrowBackIcon />
        </button>
      )}
      <ul
        className={`scrollableRow__list ${dragging ? 'dragging' : ''}`}
        ref={scrollBarRef}
        // Only attach drag handlers if drag scrolling is not disabled
        onMouseDown={!disableDragScrolling ? handleStartDrag : undefined}
        onTouchStart={!disableDragScrolling ? handleStartDrag : undefined}
        onDragStart={(e) => e.preventDefault()}
        role="region"
        tabIndex="0"
      >
        {children}
      </ul>
      {showRightButton && (
        <button
          className="scroll-button right"
          onMouseDown={() => handlePressStart('right')}
          onMouseUp={() => handlePressEnd('right')}
          onMouseLeave={handlePressLeave}
          onTouchStart={() => handlePressStart('right')}
          onTouchEnd={() => handlePressEnd('right')}
          onTouchCancel={handlePressLeave}
          aria-label="Scroll Right"
        >
          <ArrowForwardIcon />
        </button>
      )}
    </div>
  );
};

ScrollableRow.propTypes = {
  children: PropTypes.node.isRequired,
  onDraggingChange: PropTypes.func, // Add prop type for onDraggingChange
  disableDragScrolling: PropTypes.bool, // New prop type
};

ScrollableRow.defaultProps = {
  onDraggingChange: null,
  disableDragScrolling: false, // Default is drag scrolling enabled
};

export default ScrollableRow;
