const FIELD_LABELS = {
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  email: 'Email',
  password: 'Password',
  username: 'Username',
  // ...add other fields as needed...
};

export const VALIDATION_RULES = {
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  USERNAME: /^[a-zA-Z0-9_]{3,20}$/,
  PHONE: /^[0-9]{10}$/, // Adjust the regex as needed for phone number validation
  CONFIRM_PASSWORD: (password) => password, // Ensure confirm password matches
};

// Form-wide validation
export const validateForm = (formData, validationRules) => {
  const errors = {};
  let isValid = true;

  Object.keys(validationRules).forEach(fieldName => {
    const rule = validationRules[fieldName];
    const value = formData[fieldName];
    
    // Use field label
    const fieldLabel = FIELD_LABELS[fieldName] || fieldName;

    if (typeof rule === 'function') {
      if (value !== formData['password']) {
        errors[fieldName] = `${FIELD_LABELS['password']} do not match.`;
        isValid = false;
      }
    } else if (typeof rule === 'object') {
      if (rule.required && !value) {
        errors[fieldName] = `${fieldLabel} is required.`;
        isValid = false;
      }
      if (rule.matchField && value !== formData[rule.matchField]) {
        errors[fieldName] = `${FIELD_LABELS[rule.matchField]} do not match.`;
        isValid = false;
      }
      // Add more validations based on rule properties if needed
    } else if (!rule.test(value)) {
      errors[fieldName] = `Invalid ${fieldLabel}.`;
      isValid = false;
    }
  });

  return { isValid, errors };
};

// Normalize form data
export const normalizeFormData = (formData, rules) => {
  const normalized = { ...formData };
  
  Object.keys(formData).forEach(field => {
    const value = formData[field];
    if (rules[field]?.trim) {
      normalized[field] = value.trim();
    }
    if (rules[field]?.lowercase) {
      normalized[field] = value.toLowerCase();
    }
    if (rules[field]?.type === 'number') {
      normalized[field] = Number(value);
    }
  });

  return normalized;
};

// Format validation errors for display
export const formatErrors = (errors, customMessages = {}) => {
  return Object.keys(errors).reduce((acc, field) => {
    acc[field] = customMessages[field] || errors[field];
    return acc;
  }, {});
};

// Check if form is dirty (values changed from initial)
export const isFormDirty = (initialValues, currentValues) => {
  return Object.keys(initialValues).some(key => 
    initialValues[key] !== currentValues[key]
  );
};

// Get touched fields
export const getTouchedFields = (initialValues, currentValues) => {
  return Object.keys(initialValues).reduce((acc, key) => {
    acc[key] = initialValues[key] !== currentValues[key];
    return acc;
  }, {});
};

export const validateField = (fieldName, value, matchValue = null, required = false, customFieldLabel = null) => { // Added customFieldLabel parameter
  let isValid = true;
  let message = '';

  // Use customFieldLabel if provided, else use FIELD_LABELS or default
  const fieldLabel = customFieldLabel || FIELD_LABELS[fieldName] || `${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`;

  if (required && !value) {
    return {
      isValid: false,
      message: `${fieldLabel} is required.`,
    };
  }

  if (!value) {
    return { isValid: true, message: '' }; // Empty non-required field is valid
  }

  switch (fieldName) {
    case 'email':
      isValid = VALIDATION_RULES.EMAIL.test(value);
      message = isValid ? '' : 'Invalid email address.';
      break;

    case 'password':
    case 'newPassword':
      isValid = VALIDATION_RULES.PASSWORD.test(value);
      message = isValid ? '' : 'Password must be at least 8 characters long, include a number, an uppercase letter, and a lowercase letter.';
      break;

    case 'confirmPassword':
      isValid = value === matchValue;
      message = isValid ? '' : 'Passwords do not match.';
      break;

    case 'identifier':
      isValid = value.length >= 3; // Example validation
      message = isValid ? '' : 'Identifier must be at least 3 characters long.';
      break;

    case 'username':
      isValid = VALIDATION_RULES.USERNAME.test(value);
      message = isValid ? '' : 'Username must be 3-20 characters, alphanumeric with underscores.';
      break;

    default:
      isValid = true;
      message = '';
  }

  return { isValid, message };
};
