import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDownPortalTimeFrame from '../dropDownPortalTop/dropDownPortalTop';
import { setDropDownFilterMenu } from '../../../reducers/tagSlice';
import { ExpandMoreIcon } from '../../../resources/fonts/icons';
import './dropDownFilterMenu.scss';

const DropDownFilterMenu = ({ pageType }) => {
  const dispatch = useDispatch();
  const dropDownFilterMenu = useSelector((state) => state.tag.dropDownFilterMenu);

  const options = pageType === 'trending'
    ? ['Day', 'Week', 'Month']
    : ['Latest', 'Trending'];

  useEffect(() => {
    if (pageType === 'trending') {
      dispatch(setDropDownFilterMenu('Day'));
    } else {
      dispatch(setDropDownFilterMenu('Latest'));
    }
  }, [pageType, dispatch]);

  const handleSelectionChange = (selectedValue) => {
    dispatch(setDropDownFilterMenu(selectedValue));
  };

  return (
    <DropDownPortalTimeFrame 
      onSelectionChange={handleSelectionChange} 
      options={options} 
      buttonLabel={
        <>
          {dropDownFilterMenu} <ExpandMoreIcon />
        </>
      }
      className="tag-filter-button dropDownFilterMenu-dropdown"
      mobilePosition="top"
    />
  );
};

export default DropDownFilterMenu;