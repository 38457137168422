import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, clearErrors } from '../../../../reducers/profileSlice';
import { useNavigate } from 'react-router-dom';
import SettingsHeader from '../../components/settingsHeader/settingsHeader';
import Field from '../../../../components/form/field/formField.component';
import Snackbar from '../../../../components/snackbar/Snackbar.component'; // Import Snackbar
import { clearApiMessage } from '../../../../reducers/apiMessageSlice';
import './updateAccount.scss';

const UpdateEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.profile.profileData);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Snackbar state
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const serverMessage = useSelector((state) => state.apiMessages.message);

  useEffect(() => {
    dispatch(clearErrors()); // Clear errors on mount
  }, [dispatch]);

  useEffect(() => {
    if (serverMessage) {
      setSnackbarMessage(serverMessage);
      setIsSnackbarOpen(true);
    }
  }, [serverMessage]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(clearApiMessage());
    };
  }, [dispatch]);

  const handleInputChange = (e, value, validation) => {
    setEmail(value);
    if (!validation.isValid) {
      return;
    }
  };

  const handlePasswordChange = (e, value) => {
    setPassword(value);
  };

  const onSaveClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (user && user._id) {
        const updateResult = await dispatch(updateProfile({ id: user._id, data: { email, currentPassword: password } }));
        if (updateProfile.fulfilled.match(updateResult)) {
          // Remove setSnackbarMessage('Email updated successfully!');
          setIsSnackbarOpen(true);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    dispatch(clearApiMessage());
    setIsSnackbarOpen(false);
    setSnackbarMessage('');
  };

  if (!isAuthenticated) {
    navigate('/login');
    return null;
  }

  return (
    <div className="account-settings">
      <SettingsHeader title="Change Email" />
      <div className="account-settings__inner">
        <form className="update-form" onSubmit={onSaveClick}>
          <div className="form-container">
            <div className="update-form__field">
              <Field
                type="email"
                name="email"
                id="email"
                label="Email"
                initialValue={email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="update-form__field">
              <Field
                type="password"
                name="password"
                id="password"
                label="Password"
                initialValue={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
          <div className="update-form__row">
            <div className="update-form__actions">
              <button
                type="submit"
                disabled={isSubmitting}
                className="update-form__confirm"
                aria-label="Save updated email address"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        {/* Remove the old success message */}
        {/* {updateStatus === 'succeeded' && (
          <div className="update-form__message">Updated successfully!</div>
        )} */}
      </div>
      {/* Add Snackbar */}
      <Snackbar
        message={snackbarMessage}
        isOpen={isSnackbarOpen}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default UpdateEmail;