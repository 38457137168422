// useScrollableNav.js
import { useEffect, useRef, useCallback, useState, useLayoutEffect } from 'react';
import { throttle } from 'lodash'; // Ensure lodash is installed: npm install lodash
import { handleError } from '../utils/errors/errorHandler'; // Import handleError

/**
 * useScrollableNav Hook
 * Provides scrolling functionality with inertia, drag-to-scroll, and continuous scroll on button hold.
 *
 * @param {object} scrollBarRef - React ref object pointing to the scrollable container.
 * @param {object} options - Configuration options for scrolling behavior.
 * @returns {object} - Handlers and state for scrolling functionalities.
 */
const isMobileDevice = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);

const useScrollableNav = (scrollBarRef, options = {}) => {
  const {
    useVerticalScrollWheel = false,
    amplificationFactor = 1, // Increased for more responsive dragging
    deceleration = 0.0005, // Adjusted for smoother inertia
    minVelocityThreshold = 0.02, // Slight threshold to stop inertia
    initialVelocityFactor = 0.4, // Halved to reduce max inertia speed
    allowNegativeDeceleration = false,
    scrollAmount = { left: 500, right: 500 }, // Separate scroll amounts
    throttleDelay = 100, // Throttle delay for updating buttons
    scrollStep = 100, // Pixels per interval for continuous scroll
    scrollIntervalDelay = 100, // Interval delay in ms for continuous scroll
    scrollWheelStep = 50, // Pixels per wheel event for scroll wheel
    useNativeScrollingOnMobile = false,
  } = options;

  const isDragging = useRef(false);
  const [dragging, setDragging] = useState(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const lastX = useRef(0);
  const lastTime = useRef(0);
  const velocityRef = useRef(0);
  const animationFrameRef = useRef(null);
  const scrollIntervalRef = useRef(null); // For continuous scroll

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  // Throttled function to update button visibility
  const updateButtons = useCallback(
    throttle(() => {
      const scrollBar = scrollBarRef.current;
      if (!scrollBar) return;

      setShowLeftButton(scrollBar.scrollLeft > 0);
      setShowRightButton(
        scrollBar.scrollLeft < scrollBar.scrollWidth - scrollBar.clientWidth
      );
    }, throttleDelay),
    [scrollBarRef, throttleDelay]
  );

  // Update buttons on layout and scroll events
  useLayoutEffect(() => {
    updateButtons();
    const scrollBar = scrollBarRef.current;
    if (scrollBar) {
      scrollBar.addEventListener('scroll', updateButtons, { passive: true });
    }

    return () => {
      if (scrollBar) {
        scrollBar.removeEventListener('scroll', updateButtons);
      }
    };
  }, [updateButtons, scrollBarRef]);

  /**
   * animateInertia
   * Handles the inertia scrolling after drag ends.
   *
   * @param {number} initialVelocity - The initial velocity for inertia.
   */
  const animateInertia = useCallback(
    (initialVelocity) => {
      const scrollBar = scrollBarRef.current;
      if (!scrollBar) return;

      // Cancel any existing inertia animation
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      let velocity = initialVelocity * initialVelocityFactor;
      let lastTimestamp = performance.now();

      const step = (timestamp) => {
        const deltaTime = timestamp - lastTimestamp;
        lastTimestamp = timestamp;

        // Apply deceleration
        if (velocity > 0) {
          velocity = Math.max(0, velocity - deceleration * deltaTime);
        } else {
          velocity = Math.min(0, velocity + deceleration * deltaTime);
        }

        // Calculate distance
        const distance = velocity * deltaTime;
        scrollBar.scrollLeft -= distance;

        // Continue if velocity is significant
        if (Math.abs(velocity) > minVelocityThreshold) {
          animationFrameRef.current = requestAnimationFrame(step);
        } else {
          cancelAnimationFrame(animationFrameRef.current);
          animationFrameRef.current = null;
        }
      };

      animationFrameRef.current = requestAnimationFrame(step);
    },
    [scrollBarRef, deceleration, minVelocityThreshold, initialVelocityFactor]
  );

  /**
   * handleScroll
   * Handles single scroll actions when scroll buttons are clicked.
   *
   * @param {string} direction - 'left' or 'right'.
   */
  const handleScroll = useCallback(
    (direction) => {
      if (dragging) return; // Prevent button click during drag
      const scrollBar = scrollBarRef.current;
      if (scrollBar) {
        const amount =
          direction === 'left' ? scrollAmount.left : scrollAmount.right;

        // Cancel any ongoing inertia animation
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
          animationFrameRef.current = null;
          velocityRef.current = 0; // Reset velocity
        }

        const scrollOptions = {
          left: direction === 'left' ? -amount : amount,
          behavior: 'auto', // Immediate scrolling
        };
        scrollBar.scrollBy(scrollOptions);
      }
    },
    [scrollBarRef, scrollAmount, dragging]
  );

  /**
   * startContinuousScroll
   * Initiates continuous scrolling in the specified direction.
   *
   * @param {string} direction - 'left' or 'right'.
   */
  const startContinuousScroll = useCallback(
    (direction) => {
      if (dragging) return; // Prevent continuous scroll during drag
      const scrollBar = scrollBarRef.current;
      if (!scrollBar) return;

      // Cancel any ongoing inertia animation
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }

      // Avoid multiple intervals
      if (scrollIntervalRef.current) return;

      scrollIntervalRef.current = setInterval(() => {
        scrollBar.scrollLeft += direction === 'left' ? -scrollStep : scrollStep;
      }, scrollIntervalDelay);
    },
    [scrollBarRef, scrollStep, scrollIntervalDelay, dragging]
  );

  /**
   * stopContinuousScroll
   * Stops the ongoing continuous scrolling.
   */
  const stopContinuousScroll = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  }, []);

  /**
   * handleDrag
   * Handles the drag-to-scroll functionality.
   *
   * @param {object} e - The event object.
   */
  const handleDrag = useCallback(
    (e) => {
      if (!scrollBarRef.current) return;
      e.preventDefault();

      const scrollBar = scrollBarRef.current;
      const clientX = e.type.startsWith('touch') ? e.touches[0].clientX : e.clientX;

      const deltaX = (clientX - startX.current) * amplificationFactor;
      scrollBar.scrollLeft = scrollLeft.current - deltaX;

      const now = performance.now();
      const timeDiff = now - lastTime.current;

      if (timeDiff > 0) {
        const distance = clientX - lastX.current;
        const velocity = (distance / timeDiff) * amplificationFactor;
        velocityRef.current = velocity;
      }

      lastX.current = clientX;
      lastTime.current = now;
    },
    [scrollBarRef, amplificationFactor]
  );

  /**
   * handleEndDrag
   * Ends the drag-to-scroll and initiates inertia if applicable.
   */
  const handleEndDrag = useCallback(() => {
    if (!isDragging.current) return;
    isDragging.current = false;
    setDragging(false);

    // Remove event listeners
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleEndDrag);
    document.removeEventListener('touchmove', handleDrag);
    document.removeEventListener('touchend', handleEndDrag);

    const velocity = velocityRef.current;

    // Trigger inertia animation
    if (Math.abs(velocity) > 0 || allowNegativeDeceleration) {
      animateInertia(velocity);
    }
  }, [handleDrag, animateInertia, allowNegativeDeceleration]);

  /**
   * handleStartDrag
   * Initiates the drag-to-scroll functionality.
   *
   * @param {object} e - The event object.
   */
  const handleStartDrag = useCallback(
    (e) => {
      if (isMobileDevice()) return; // Disable drag on mobile

      // Cancel any ongoing inertia animation
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }

      isDragging.current = true;
      setDragging(true);
      velocityRef.current = 0;

      const clientX = e.type.startsWith('touch') ? e.touches[0].clientX : e.clientX;
      startX.current = clientX;
      scrollLeft.current = scrollBarRef.current.scrollLeft;

      lastX.current = clientX;
      lastTime.current = performance.now();

      // Add event listeners
      document.addEventListener('mousemove', handleDrag, { passive: false });
      document.addEventListener('mouseup', handleEndDrag);
      document.addEventListener('touchmove', handleDrag, { passive: false });
      document.addEventListener('touchend', handleEndDrag);
    },
    [handleDrag, handleEndDrag, scrollBarRef, useNativeScrollingOnMobile]
  );

  /**
   * handleWheel
   * Handles wheel scrolling events with granular control.
   *
   * @param {object} e - The wheel event object.
   */
  const handleWheel = useCallback(
    (e) => {
      try {
        const scrollBar = scrollBarRef.current;
        if (!scrollBar) return;

        if (useVerticalScrollWheel) {
          // Use vertical scroll wheel to scroll horizontally
          e.preventDefault();
          const scrollDelta = e.deltaY * (scrollWheelStep / 100); // Adjust scrollDelta based on scrollWheelStep
          scrollBar.scrollLeft += scrollDelta;
          // No inertia applied to wheel scrolling
        } else if (Math.abs(e.deltaX) > 0) {
          e.preventDefault();
          const scrollDelta = e.deltaX * (scrollWheelStep / 100); // Adjust scrollDelta based on scrollWheelStep
          scrollBar.scrollLeft += scrollDelta;
          // No inertia applied to wheel scrolling
        }
      } catch (error) {
        handleError(error, 'useScrollableNav.handleWheel');
        // Optionally, handle the error as needed
      }
    },
    [scrollBarRef, useVerticalScrollWheel, scrollWheelStep]
  );

  // Attach wheel event listener
  useEffect(() => {
    if (isMobileDevice() && useNativeScrollingOnMobile) {
      return; // Skip custom wheel events on mobile if using native scroll
    }
    const scrollBar = scrollBarRef.current;
    if (!scrollBar) return;

    scrollBar.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      if (scrollBar) scrollBar.removeEventListener('wheel', handleWheel);
    };
  }, [handleWheel, scrollBarRef, useNativeScrollingOnMobile]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Cleanup dragging event listeners
      if (isDragging.current) {
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleEndDrag);
        document.removeEventListener('touchmove', handleDrag);
        document.removeEventListener('touchend', handleEndDrag);
        isDragging.current = false;
        setDragging(false);
      }

      // Cancel any ongoing inertia animation
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      // Clear continuous scroll interval
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [handleDrag, handleEndDrag]);

  return {
    handleScroll,
    handleStartDrag,
    startContinuousScroll,
    stopContinuousScroll,
    dragging,
    showLeftButton,

    showRightButton,
  };
};

export default useScrollableNav;
