import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  sendVerificationEmail,
} from '../../reducers/profileSlice';
import './emailVerification.component.css';

const EmailVerification = ({
  user,
  sendVerificationEmailStatus,
  sendVerificationEmailError,
  sendVerificationEmail,
}) => {

  useEffect(() => {
    let toastMessage = '';
    if (sendVerificationEmailStatus === 'succeeded') {
      toastMessage = `Email sent to ${user?.username}`;
    } else if (sendVerificationEmailStatus === 'failed') {
      toastMessage = sendVerificationEmailError;
    }

    if (toastMessage) {
    }
  }, [sendVerificationEmailStatus, user?.username, sendVerificationEmailError]);

  const verifyEmailHandler = () => {
    if (user) {
      sendVerificationEmail({ email: user.username, username: user.username });
    } else {
      console.log('Error: User not authenticated.');
    }
  };

  if (!user || user.isEmailVerified) {
    return null;
  }

  return (
    <div className="email-verification_container">
      <span>Your email is not verified. </span>
      <button onClick={verifyEmailHandler}>Verify email.</button>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { profile } = state;
  const { user, sendVerificationEmailStatus, sendVerificationEmailError } = profile;
  return {
    user,
    sendVerificationEmailStatus,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = {
  sendVerificationEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
