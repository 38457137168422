import { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

/**
 * Custom hook to handle infinite scrolling.
 * It observes the targetRef and triggers fetchItems when the target is in view.
 */
const useInfiniteScroll = ({
  fetchItems,
  threshold = 0.1,
  rootMargin = '100px',
  debounceTime = 150,
  targetRef,
  enabled = true,
}) => {
  const observer = useRef(null);
  const isLoading = useRef(false);

  const loadMore = useCallback(async () => {
    if (!fetchItems || isLoading.current) return;

    try {
      isLoading.current = true;
      await fetchItems();
    } finally {
      isLoading.current = false;
    }
  }, [fetchItems]);

  const debouncedLoadMore = useCallback(
    debounce(loadMore, debounceTime, { leading: true, trailing: false }),
    [loadMore, debounceTime]
  );

  useEffect(() => {
    if (!enabled || !fetchItems || !targetRef) { // Check for targetRef existence
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
      return;
    }

    const currentTarget = targetRef;

    if (!currentTarget) return;

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          debouncedLoadMore();
        }
      },
      {
        root: null,
        rootMargin,
        threshold,
      }
    );

    observer.current.observe(currentTarget);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
      debouncedLoadMore.cancel();
      isLoading.current = false;
    };
  }, [debouncedLoadMore, rootMargin, threshold, fetchItems, targetRef, enabled]);
};

useInfiniteScroll.propTypes = {
  fetchItems: PropTypes.func,
  threshold: PropTypes.number,
  rootMargin: PropTypes.string,
  debounceTime: PropTypes.number,
  targetRef: PropTypes.instanceOf(Element), // Changed to Element for better type checking
  enabled: PropTypes.bool,
};

export default useInfiniteScroll;
