// src/components/authentication/authentication.component.js

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewSwitch, Modal, LoginComponent, RegisterForm, ForgotPasswordForm } from '../../components';
import { useDispatch } from 'react-redux';
import { loginUser, registerUser } from '../../reducers/authSlice';
import { sendForgotPasswordEmail } from '../../reducers/profileSlice';
import { setApiMessage } from '../../reducers/apiMessageSlice'; // New import
import { ArrowBackIcon } from '../../resources/fonts/icons'; // Already imported in forgotPassword; reuse here if needed.
import { Button } from '../../components';
import './authentication.component.scss';

const AuthenticationComponent = ({
  formType,
  onClearErrorStatus,
  profile,
  isVisible,
  onHide,
  // errorMessage, // Remove this prop as it's no longer used
}) => {
  const dispatch = useDispatch();
  const [activeViewId, setActiveViewId] = useState('login');
  const [forms, setForms] = useState({
    registrationForm: {
      password: '',
      confirmPassword: '',
      email: '',
      username: '', // renamed from 'name'
      phone: '',
    },
    loginForm: {
      identifier: '',
      password: '',
    },
  });

  useEffect(() => {
    setActiveViewId(formType || 'login');
  }, [formType]);

  const resetForm = useCallback((formType) => {
    setForms((prevForms) => ({
      ...prevForms,
      [formType]:
        formType === 'registrationForm'
          ? { email: '', password: '', confirmPassword: '', username: '', phone: '' } // renamed 'name' to 'username'
          : { identifier: '', password: '' }, // changed from email
    }));
  }, []);

  const handleViewSwitch = useCallback((newActiveViewId) => {
    onClearErrorStatus();

    if (activeViewId !== newActiveViewId) {
      setActiveViewId(newActiveViewId);
      if (newActiveViewId !== 'login') { // Only reset non-login forms
        resetForm(newActiveViewId === 'registration' ? 'registrationForm' : newActiveViewId);
      }
      // Do not reset 'loginForm' to preserve autofilled values
    }
  }, [activeViewId, onClearErrorStatus, resetForm]);

  const handleFieldChange = useCallback((formType, event, value) => {
    const { name } = event.target;
    setForms((prevForms) => ({
      ...prevForms,
      [formType]: {
        ...prevForms[formType],
        [name]: value,
      },
    }));
  }, []);

  const handleFormClick = useCallback((type, params) => {
    if (!params) return;

    if (type === 'login' && params.isValid) {
      dispatch(loginUser(params.values))
        .unwrap()
        .then(() => {
          // Success handled in AuthenticationContainer
        })
        .catch(() => { 
          // Error is handled by AuthenticationContainer via Redux state
        });
    }

    if (type === 'registration' && params.isValid) {
      dispatch(registerUser(params.values))
        .unwrap()
        .then(() => {
          // Success handled in AuthenticationContainer
        })
        .catch(() => { 
          // Error is handled by AuthenticationContainer via Redux state
        });
    }

    if (type === 'forgotPassword' && params.isValid) {
      dispatch(sendForgotPasswordEmail({ identifier: params.identifier }))
        .unwrap()
        .then((result) => {
          dispatch(setApiMessage(result.message));
        })
        .catch((error) => {
          dispatch(setApiMessage(error));
        });
    }
  }, [dispatch]);

  const renderLoginForm = useCallback(() => {
    const { identifier, password } = forms.loginForm; // changed from email
    const errorCode = profile.error ? 'Error' : '';
    const message = profile.error || '';

    return (
      <LoginComponent
        identifier={identifier} // changed from email
        password={password}
        authStatus={errorCode}
        authMessage={message}
        onLoginClick={(params) => handleFormClick('login', { ...params })}
        handleLoginFieldChange={(event, value) => handleFieldChange('loginForm', event, value)}
        handleViewSwitch={handleViewSwitch}
      />
    );
  }, [forms.loginForm, profile.error, handleFormClick, handleFieldChange, handleViewSwitch]);

  const renderRegistrationForm = useCallback(() => {
    const { password, confirmPassword, email, username, phone } = forms.registrationForm; // renamed 'name' to 'username'
    const errorCode = profile.error ? 'Error' : '';
    const message = profile.error || '';

    return (
      <RegisterForm
        password={password}
        confirmPassword={confirmPassword}
        email={email}
        username={username} // pass 'username'
        phone={phone}
        authStatus={errorCode}
        authMessage={message}
        onRegisterClick={(params) => handleFormClick('registration', { ...params })}
        onFieldChange={(event, value) => handleFieldChange('registrationForm', event, value)}
        handleViewSwitch={handleViewSwitch}
      />
    );
  }, [forms.registrationForm, profile.error, handleFormClick, handleFieldChange, handleViewSwitch]);

  const renderForgotPasswordForm = useCallback(() => (
    <ForgotPasswordForm
      handleViewSwitch={handleViewSwitch}
      onForgotPasswordClick={(params) => handleFormClick('forgotPassword', { ...params })}
    />
  ), [handleViewSwitch, handleFormClick]);

  const handleModalClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    // Additional validation or side-effects if necessary
  }, [forms.loginForm, forms.registrationForm]);

  // Ensure that profile.error is correctly mapped from authSlice
  useEffect(() => {
    if (profile.getProfileError) { // Check if getProfile error affects authentication
      onClearErrorStatus();
    }
  }, [profile.getProfileError, onClearErrorStatus]);

  // Create back button element to use in the Modal header when in forgotPassword view.
  const modalBackButton = activeViewId === 'forgotPassword' ? {
    icon: (
      <Button
        variant="icon"
        onClick={() => handleViewSwitch('login')}
        className="modal-back-button"
        style={{ padding: 0, cursor: 'pointer' }}
      >
        <ArrowBackIcon />
      </Button>
    ),
    onClick: () => handleViewSwitch('login'),
  } : null;

  return (
    <Modal
      isVisible={isVisible}
      disableEscToClose={false}
      includeExit
      onHide={onHide}
      onClick={handleModalClick}
      // Pass the back button when active view is forgotPassword
      backButton={modalBackButton}
    >
      <div className="auth_modal">
        <ViewSwitch
          activeViewId={activeViewId}
          views={[
            { id: 'login', component: renderLoginForm },
            { id: 'registration', component: renderRegistrationForm },
            { id: 'forgotPassword', component: renderForgotPasswordForm },
          ]}
          handleViewSwitch={handleViewSwitch}
        />
      </div>
    </Modal>
  );
};

// Removed PropTypes.object and replaced with more specific types if possible
AuthenticationComponent.propTypes = {
  formType: PropTypes.string,
  onClearErrorStatus: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // errorMessage: PropTypes.string, // Remove this propType
};

export default React.memo(AuthenticationComponent);