// src/hooks/useSubscription.js

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSubscriptionStatus } from '../reducers/subscriptionsSlice';
import { authEventEmitter } from '../containers/navigation/navigation.container';

const useSubscription = ({
  subscriptionTopicId,
  initialSubscribed,
  onUnsubscribe,
  onClickOnly = false,
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const profileId = useSelector((state) => state.profile.profileData?._id);
  const globalSubscriptions = useSelector(
    (state) => state.subscriptions.subscriptions || []
  );

  const [isSubscribed, setIsSubscribed] = useState(initialSubscribed);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!onClickOnly) {
      // Sync with global state in non-modal usage
      const currentlySubscribed = globalSubscriptions.some(
        (subscription) => subscription.topic?._id === subscriptionTopicId
      );
      setIsSubscribed(currentlySubscribed);
    }
  }, [globalSubscriptions, subscriptionTopicId, onClickOnly]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onToggleSubscription = async () => {
    try {
      if (!isAuthenticated) {
        authEventEmitter.emit('triggerAuth');
        return;
      }

      if (!profileId) {
        return;
      }

      if (onClickOnly && onUnsubscribe) {
        onUnsubscribe();
        setIsSubscribed(!isSubscribed); // Added to update local state in modal mode
        return;
      }

      const actionType = isSubscribed ? 'unsubscribe' : 'subscribe';
      await dispatch(
        toggleSubscriptionStatus({
          subscriptionTopicId,
          profileId,
          actionType,
        })
      ).unwrap();
      setIsSubscribed(!isSubscribed);
    } catch (error) {
      handleError(error, 'useSubscription.onToggleSubscription');
      // Optionally, handle the error as needed
    }
  };

  return {
    isSubscribed,
    isAuthenticated,
    isHovered,
    handleMouseEnter,
    handleMouseLeave,
    onToggleSubscription,
  };
};

export default useSubscription;

